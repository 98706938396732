import { RefObject, createContext, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, Flex } from "@chakra-ui/react";

import References from "../references";
import MarkedReply from "./MarkedReply";
import BotMessageActions from "./BotMessageActions";
import FollowUpQuestions from "./FollowUpQuestions";

import { ConversationProps, SourceProps } from "models/chat/MessageProps";
import HTMLRender from "components/ui/HTMLRender";

interface BotMessageProps {
  botResponse: ConversationProps;
  isLastMessage: boolean;
  messagesRef: RefObject<HTMLDivElement>;
}

export interface BotMessageContextProps {
  openAccordionIndex: number | null;
  setOpenAccordionIndex: React.Dispatch<React.SetStateAction<number | null>>;
  resetOpenAccordionIndex: () => void;
  scrollToAccordion: () => void;
  messagesRef: RefObject<HTMLDivElement> | null;
  openAccordionRef: RefObject<HTMLDivElement> | null;
}

export const BotMessageContext = createContext<BotMessageContextProps>({
  openAccordionIndex: null,
  setOpenAccordionIndex: () => {},
  resetOpenAccordionIndex: () => {},
  scrollToAccordion: () => {},
  messagesRef: null,
  openAccordionRef: null,
});

export default function BotMessage({
  botResponse,
  isLastMessage,
  messagesRef,
}: BotMessageProps) {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  // States
  // const [isHovered, setIsHovered] = useState(false); // this causes the rerender of component that cause networks change shape
  const actionButtonsRef = useRef<HTMLDivElement>(null);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null
  );

  // Hooks
  const openAccordionRef = useRef<HTMLDivElement | null>(null);

  // Handlers
  function resetOpenAccordionIndex() {
    setOpenAccordionIndex(null);
  }

  function scrollToAccordion() {
    if (openAccordionRef.current && messagesRef.current) {
      const acc = openAccordionRef.current;
      const messagesContainer = messagesRef.current;

      // Get the position of the child relative to the messages container
      const accRect = acc.getBoundingClientRect();
      const containerRect = messagesContainer.getBoundingClientRect();

      // Calculate the scroll position
      const scrollToY =
        accRect.top + messagesContainer.scrollTop - containerRect.top - 5;

      // Perform the scroll
      messagesContainer.scrollTo({
        top: scrollToY,
        behavior: "smooth",
      });
    }
  }

  const currentMessageIndex = botResponse.messages.length - 1;
  const hasError = botResponse.messages[currentMessageIndex]?.ai?.includes(
    "I'm currently experiencing technical difficulties"
  );

  const currentMessage = botResponse?.messages[currentMessageIndex];

  // TODO: persist followup question on page reload - to store content in DB
  const followupQuestions: string[] | undefined =
    botResponse?.followup_questions?.flat(); // NOTE: flat used to concatenate all sub-array elements into one array

  const compounds: string[] | undefined = botResponse?.compounds;
  const proteins: string[] | undefined = botResponse?.proteins;
  const sources = useMemo(() => {
    return (
      botResponse?.sources.reduce((acc: SourceProps[], item) => {
        const existingSrc = acc.find((src: SourceProps) => src.id === item.id);
        if (!existingSrc) {
          acc.push(item);
        }
        return acc;
      }, []) || []
    );
  }, [botResponse]);

  const handleMouseEnter = () => {
    if (actionButtonsRef.current) {
      actionButtonsRef.current.style.display = "block";
    }
  };

  const handleMouseLeave = () => {
    if (actionButtonsRef.current) {
      actionButtonsRef.current.style.display = "none";
    }
  };

  return (
    <BotMessageContext.Provider
      value={{
        openAccordionIndex,
        setOpenAccordionIndex,
        resetOpenAccordionIndex,
        messagesRef,
        openAccordionRef,
        scrollToAccordion,
      }}
    >
      <Card
        position="relative"
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        boxShadow={"none"}
        bg={"transparent"}
        p={3}
        py={1}
        w={"100%"}
        color={hasError ? "orange.400" : ""}
      >
        <Flex
          h={"full"}
          w={"full"}
          direction={"column"}
          gap={2}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          position="relative"
          pb={7}
        >
          <MarkedReply reply={currentMessage.ai} sources={sources} />

          {currentMessage.json_response &&
            currentMessage.json_response.length > 5 &&
            currentMessage.json_response.includes("minified_html") && (
              <HTMLRender json_response={currentMessage.json_response} />
            )}

          {/* Bot Answer footer */}
          {/* use ref to stop component rerender on state change on hover which causes network rerender, forming diffrent change all time on hover */}
          <Box
            ref={actionButtonsRef}
            w={"100%"}
            position="absolute"
            right={0}
            bottom={1}
          >
            <BotMessageActions data={botResponse} />
          </Box>
        </Flex>

        {botResponse && (
          <>
            {(!!sources?.length ||
              !!compounds?.length ||
              !!proteins?.length) && (
              <References
                sources={sources}
                molecules={compounds}
                proteins={proteins}
              />
            )}

            {/* follow-up questions */}
            {followupQuestions && !!followupQuestions?.length && (
              <FollowUpQuestions
                questions={followupQuestions}
                onSendFollowUpQuestion={handleSendQuestion}
              />
            )}
          </>
        )}
      </Card>
    </BotMessageContext.Provider>
  );
}
