import { useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";

import { useChatBotAPI } from "api/useChatBotAPI";
import ChatToExport from "../toExport/ChatToExport";
import { ConversationProps } from "models/chat/MessageProps";

import { MdFileDownload } from "react-icons/md";

interface ExportModalProps {
  id: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function ExportModal({ id, isOpen, onClose }: ExportModalProps) {
  // Hooks
  const queryClient = useQueryClient();

  // API
  const { fetchChatById } = useChatBotAPI();

  const cachedData = queryClient.getQueryData(["chatbot-session", id]);
  const title = queryClient.getQueryData(["sessionTitle"]);

  const {
    isLoading: loadingChat,
    data: chatData,
    error: chatError,
  } = useQuery({
    queryKey: ["chatbot-session", id],
    queryFn: fetchChatById,
    enabled: !!id && !cachedData, // avoid redundant fetches, if the session is cached
  });

  // PDF document instance
  const [instance, updateInstance] = usePDF({
    document: (
      <ChatToExport
        title={title as string}
        messages={chatData as ConversationProps[]}
      />
    ),
  });

  const {
    loading: loadingDocument,
    error: errorDocument,
    url: documentURL,
  } = instance;

  useEffect(() => {
    if (chatData && title) {
      updateInstance(
        <ChatToExport
          title={title as string}
          messages={chatData as ConversationProps[]}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, title]);

  const hasError = !!errorDocument || !!chatError;
  const isExportDisabled = loadingDocument || loadingChat || !documentURL;

  const state =
    loadingDocument || loadingChat
      ? { text: "Generating document..", theme: "orange.500" }
      : hasError
      ? { text: "Failed to generate the document.", theme: "red.500" }
      : { text: "Your document is ready for export.", theme: "teal.500" };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        bg={"background"}
        borderRadius={"6px"}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={MdFileDownload}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            Export chat as PDF document
          </Text>
        </ModalHeader>

        <ModalBody>
          <Text
            fontWeight={"500"}
            fontSize={"14px"}
            color={state?.theme ?? "gray.600"}
            minH={"22px"} // To prevent quick UI flickering
          >
            {state?.text}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            as={"a"}
            href={documentURL ?? ""}
            download={(title as string) ?? "chat title"}
            bg={"highlight.primary"}
            color={"gray.50"}
            border={"none"}
            borderRadius={"100px"}
            _hover={{ opacity: 0.9 }}
            isDisabled={isExportDisabled}
            pointerEvents={isExportDisabled ? "none" : "auto"}
          >
            Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
