import { Card, Flex } from "@chakra-ui/react";

import BotAvatar from "./BotAvatar";
import MarkedReply from "./MarkedReply";
import LoadingDots from "components/ui/LoadingDots";

import { removeBraces } from "../helpers";

interface LoadingBubbleProps {
  partialReply: string[];
  notifications: string[];
}

export default function LoadingBubble({
  partialReply,
  notifications,
}: LoadingBubbleProps) {
  // NOTE: When general question being asked (ex: what causes malaria? without using your database),
  // the model's response was inconsistent for a specific character:
  // instead of returning \n\n, it returns weird characters: \\, \n\, \\n, etc..
  // => custom tailoring was required to ensure accuracy
  // if we can refine this streaming behavior on the backend, that'd be better
  const processedReply = partialReply.map((item, i, arr) => {
    if (item.includes(`\\`)) {
      item = item.replace(/\\/g, "\n\n");

      if (arr[i + 1]?.trim()?.startsWith("n"))
        arr[i + 1] = arr[i + 1].replace(/^\s*n/, "");
    }

    return item.replace(/\n\nn/g, "\n\n");
  });

  return (
    <Flex w="100%" mt={4} mb={8} minH={"50px"} overflow={"hidden"}>
      <BotAvatar />

      <Card p={3} bg={"transparent"} boxShadow={"none"}>
        {/* partial bot reply without molecules or sources */}
        {!!processedReply?.length && (
          <Flex
            h={"100%"}
            w={"100%"}
            direction={"column"}
            gap={1.5}
            mb={!!processedReply?.length ? 5 : "0"}
          >
            {/* TODO: add typing character at the end of the streaming buffer */}
            <MarkedReply reply={removeBraces(processedReply)?.join("")} />
          </Flex>
        )}

        {/* notifications */}
        <Flex h={"100%"} w={"100%"} gap={3} align={"center"}>
          {/* dots indicators */}
          <LoadingDots />

          {/* notification message */}
          <Flex
            fontSize={"13px"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={"1.7"}
            color={"gray.500"}
          >
            {notifications?.at(-1) || ""}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
