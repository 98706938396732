import { Text, View, Font } from "@react-pdf/renderer";
import ReactMarkdown, { Components } from "react-markdown";
import { styles } from "./helpers";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { SourceProps } from "models/chat/MessageProps";

interface Props {
  reply: string;
  sources?: SourceProps[];
}

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
    { src: "/fonts/Roboto-MediumItalic.ttf", fontStyle: "italic" },
    {
      src: "/fonts/Roboto-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

function MarkedReplyToExport({ reply }: Props) {
  const ReactPDFComponents: Components = {
    h1: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h1}>
          {props.children}
        </Text>
      ) : null;
    },
    h2: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h2}>
          {props.children}
        </Text>
      ) : null;
    },
    h3: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h3}>
          {props.children}
        </Text>
      ) : null;
    },
    h4: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h4}>
          {props.children}
        </Text>
      ) : null;
    },
    h5: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h5}>
          {props.children}
        </Text>
      ) : null;
    },
    h6: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.h6}>
          {props.children}
        </Text>
      ) : null;
    },
    em: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.em}>
          {props.children}
        </Text>
      ) : null;
    },
    strong: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.strong}>
          {props.children}
        </Text>
      ) : null;
    },
    ul: ({ node, ...props }) => {
      return props.children ? (
        <View wrap style={styles.list}>
          {props.children}
        </View>
      ) : null;
    },
    ol: ({ node, ...props }) => {
      return props.children ? (
        <View wrap style={styles.list}>
          {props.children}
        </View>
      ) : null;
    },
    li: ({ node, ...props }) => {
      return props.children ? (
        <Text style={styles.li}>
          - {props.children}
          {"\n"}
        </Text>
      ) : null;
    },
    span: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.paragraph}>
          {props.children}
        </Text>
      ) : null;
    },
    p: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.paragraph}>
          {props.children}
        </Text>
      ) : null;
    },
    code: ({ node, ...props }) => {
      return props.children ? (
        <Text wrap style={styles.paragraph}>
          {props.children}
        </Text>
      ) : null;
    },
  };

  return (
    <ReactMarkdown
      components={ReactPDFComponents}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    >
      {reply ?? "cannot load the reply"}
    </ReactMarkdown>
  );
}
export default MarkedReplyToExport;
