import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { setCurentUserLimit } from "utils/helpers";

export const useLiteraturesAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchSimilarPapers = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , id, type = "drug"] = queryKey;
    if (!id) return [];
    const url = `${environment.BACKEND_API}/api/get_similar_papers/${id}/${type}`;
    const response = await axiosPrivate.get(url);

    return response.data;
  };

  const fetchPapersById = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, id, layer] = queryKey;
    const url = `${environment.BACKEND_API}/api/get_paper_by_id/${id}`;
    const response = await axiosPrivate.get(url, { params: { layer } });
    setCurentUserLimit();

    return response.data;
  };

  return { fetchSimilarPapers, fetchPapersById };
};
