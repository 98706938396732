import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationProps } from "models/notifications/NotificationProps";
import { createSelector } from "reselect";

export interface NotificationsState {
  notifications: NotificationProps[];
  unreadCount: number;
  error: string;
}

const initialState: NotificationsState = {
  notifications: [],
  unreadCount: 0,
  error: "",
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setNotifications: (state, action: PayloadAction<NotificationProps[]>) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action: PayloadAction<NotificationProps>) => {
      state.notifications.unshift(action.payload);
      if (!action.payload?.is_read) {
        state.unreadCount += 1;
      }
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.unreadCount = action.payload;
    }
  },
});

export const { setNotifications, addNotification, setUnreadCount, setError } = notificationsSlice.actions;

export default notificationsSlice.reducer;

const selectNotificationsState = (state: {
  notifications: NotificationsState;
}) => state.notifications;

export const selectCurrentNotificationsData = createSelector(
  [selectNotificationsState],
  (notifications) => ({
    notifications: notifications.notifications,
    unreadCount: notifications.unreadCount,
    error: notifications.error,
  })
);
