import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useDataLibraryAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchTableData = async ({ queryKey, signal }: any) => {
    const [, params] = queryKey;
    const { query, page, itemsPerPage, sort, order, source, filters } = params;
    const url = `${environment.BACKEND_API}/api/data_library_core`;
    const res = await axiosPrivate.get(url, {
      params: {
        query,
        page,
        itemsPerPage,
        source,
        sort,
        order,
        filters: JSON.stringify(filters),
      },
      signal,
    });

    return res.data;
  };

  const fetchMRNATableData = async ({ queryKey, signal }: any) => {
    const [, params] = queryKey;
    const { query, page, itemsPerPage, sort, order, source, filters } = params;
    const url = `${environment.BACKEND_API}/api/data_library_mrna`;
    const res = await axiosPrivate.get(url, {
      params: {
        query,
        page,
        itemsPerPage,
        source,
        sort,
        order,
        filters: JSON.stringify(filters),
      },
      signal,
    });

    return res.data;
  };

  const fetchProprietaryTableData = async ({ queryKey, signal }: any) => {
    const [, params] = queryKey;
    const { query, page, itemsPerPage, sort, order, source, filters } = params;
    const url = `${environment.BACKEND_API}/api/proprietaries`;
    const res = await axiosPrivate.get(url, {
      params: {
        query,
        page,
        itemsPerPage,
        source,
        sort,
        order,
        filters: JSON.stringify(filters),
      },
      signal,
    });
    return res.data;
  };

  const deleteProprietaryPaper = async (paperId: string) => {
    const url = `${environment.BACKEND_API}/api/proprietaries/${paperId}`;

    try {
      const response = await axiosPrivate.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchTableData,
    fetchProprietaryTableData,
    fetchMRNATableData,
    deleteProprietaryPaper,
  };
};
