import { Dispatch, SetStateAction } from "react";

import persona1 from "assets/home/pipeline/persona1.png";
import persona2 from "assets/home/pipeline/persona2.png";
import persona3 from "assets/home/pipeline/persona3.png";
import persona4 from "assets/home/pipeline/persona4.png";
import persona5 from "assets/home/pipeline/persona5.png";

import { PiDna, PiBrain } from "react-icons/pi";
import { TbCell } from "react-icons/tb";

export interface FormDataProps {
  name: string;
  email: string;
  message: string;
}

export interface ErrorsProps {
  name: boolean;
  email: boolean;
  message: boolean;
}

export interface EnterpriseProps {
  name: string;
  members: number;
  message?: string;
  lmic?: string;
  email?: string;
  is_yearly: boolean;
  username: string;
}

export interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface FeatureProps {
  name: string;
  description: string;
  pictures: {
    before: string;
    after?: string;
  };
}

export interface UseCase {
  title: string;
  description: string;
  colors: string[];
  icon: any;
}

export function checkFormDataValidation(
  formData: FormDataProps,
  setErrors: Dispatch<SetStateAction<ErrorsProps>>
) {
  const { name, email } = formData;
  // validate name
  const isValidName = name?.trim().length >= 3;
  setErrors((prev: ErrorsProps) => ({ ...prev, name: !isValidName }));

  // validate email
  const isValidEmail = email?.trim().length >= 3 && /^\S+@\S+$/i.test(email);
  setErrors((prev: ErrorsProps) => ({ ...prev, email: !isValidEmail }));

  return isValidName && isValidEmail;
}

export function getTimeRemaining(targetDate: string): TimeRemaining {
  const now = new Date();
  const distance = new Date(targetDate).getTime() - now.getTime();

  if (distance < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export const pipelineStages = [
  {
    name: "Literature Review",
    description: {
      text: "Lakesai streamlines your literature review process with comprehensive analysis of the latest research in your field. Access an extensive range of academic databases and seamlessly integrate your own collection of papers.",
      highlight: ["literature review", "academic databases", "integrate"],
      state: "",
    },
    example: {
      query:
        "What is the state of the art on mRNA vaccine development for COVID-19?",
      avatar: persona1,
    },
    color: "#00C1B4",
  },
  {
    name: "Hypothesis Design",
    description: {
      text: "Harness advanced models to generate and test new hypotheses, while integrating your own tools to enhance the research process.",
      highlight: [
        "generate hypotheses",
        "advanced models",
        "integrating tools",
      ],
      state: "",
    },
    example: {
      query:
        "What are potential drug candidates to repurpose for tuberculosis?",
      avatar: persona2,
    },
    color: "#2dd6cb",
  },
  {
    name: "Design & Validation",
    description: {
      text: "Lakesai reviews your work against existing literature, ensuring alignment and novelty. Collaborate seamlessly with your team for thorough peer reviews.",
      highlight: ["validate", "alignment", "peer reviews"],
      state: "",
    },
    example: {
      query:
        "Validate the following text to ensure it aligns with existing literature and assesses its novelty.",
      avatar: persona3,
    },
    color: "#2dafd6",
  },
  {
    name: "Reporting",
    description: {
      text: "Effortlessly generate drafts and detailed reports with our automated writing tools. Manage citations with ease, adhering to various academic standards.",
      highlight: [
        "automated writing",
        "manage citations",
        "academic standards",
      ],
      state: "coming soon",
    },
    example: {
      query:
        "Please review the following text and generate a report based on my bookmarked notes.",
      avatar: persona4,
    },
    color: "#1A86CE",
  },
  {
    name: "Dissemination",
    description: {
      text: "Submit your research to our central repository, where users can engage by commenting and liking your work. Notifications will alert users to new submissions.",
      highlight: ["submit research", "engage", "notifications"],
      state: "in progress",
    },
    example: {
      query:
        "Hi, I would like to submit my paper to be shared with the Lakesai community. Please review the attachment.",
      avatar: persona5,
    },
    color: "#093654",
  },
];

export const frequentlyAsked = [
  {
    title: "What makes Lakesai different from other AI research assistants?",
    description:
      "Lakesai was designed with a unique focus on generative AI applications in drug development, life sciences and solving global health challenges. It empowers you to bring discoveries to market faster by supporting the entire research and design pipeline. Backed by Vitafluence's expertise, Lakesai combines cutting-edge technology with a mission to drive meaningful global impact.",
  },
  {
    title: "When and why was Lakesai created?",
    description:
      "Vitafluence created Lakesai in 2024 as part of a project supported by the Bill and Melinda Gates Foundation. Our mission is to harness the latest advancements in generative AI for the benefit of humanity, particularly in AI-driven research and initiatives in developing countries. This unique focus positions us as leaders in innovation and drivers of positive global impact.",
  },
  {
    title: "Is there a cost to using Lakesai?",
    description:
      "Lakesai offers a range of pricing plans, including a free tier for basic features and paid options for advanced functionalities. This ensures that our platform is accessible to users with different needs and resources.",
  },
  {
    title: "Does Lakesai support collaboration?",
    description:
      "Yes, Lakesai is built to support collaborative research. You can collaborate with colleagues, and work together on research tasks, making it easier to advance your work in a team setting. See enterprise subscription here.",
    links: [{ here: "" }],
  },
  {
    title: "How secure is my data?",
    description:
      "Your data security is our top priority. We adhere strictly to the General Data Protection Regulation (GDPR) standards, ensuring that your personal information is protected and your privacy is respected. For more details on how we handle your data, please review our privacy policy.",
  },
  {
    title: "Can I add my own research papers?",
    description:
      "Absolutely! You can upload and manage your own research papers within Lakesai. This allows you to integrate your work seamlessly into the platform for further analysis and collaboration.",
  },
  {
    title: "How can I give feedback?",
    description:
      "We value your input. To provide feedback, simply click the feedback button located on the center-right side of the application. Whether you want to suggest a new feature, offer a compliment, or report an issue, your feedback helps us improve Lakesai.",
  },
  {
    title: "How often is Lakesai updated?",
    description:
      "We continuously improve Lakesai by releasing updates regularly. These updates include new features, security enhancements, and performance improvements to ensure you have the best experience possible. We are also working hard to provide more layers of data and models to make Lakesai smarter and most reliable.",
    links: [{ here: "" }],
  },
  {
    title: "How can I contact you?",
    description:
      "For any inquiries, you can reach us via email at connect@vitafluence.ai. Our team is committed to responding to your messages as promptly as possible.",
  },
];

export const features: FeatureProps[] = [
  {
    name: "Be Grounded In Evidence",
    description:
      "Dive into insights powered by over 1 million research papers, all meticulously curated to ensure you get the most reliable, evidence-based information available.",
    pictures: {
      before: "f1.png",
      after: "f1-hover.png",
    },
  },
  {
    name: "Upload Your Files",
    description:
      "Effortlessly upload your documents and let Lakesai bring them to life — get summaries, detailed explanations, and more to enrich your understanding.",
    pictures: {
      before: "f2.png",
      after: "sources2.png",
    },
  },
  {
    name: "Multi-Modal Capacity",
    description:
      "Our advanced technology reads and interprets text, images, and tables, giving you a comprehensive understanding like never before.",
    pictures: {
      before: "f3.png",
      after: "multi-modal-hover.png",
    },
  },
  {
    name: "Always Know Your Sources",
    description:
      "We provide transparent citations and a citation network, ensuring you can trace and verify every piece of information with ease. ",
    pictures: {
      before: "f4.png",
      after: "sources-know-hover.png",
    },
  },
  {
    name: "Team Collaboration",
    description:
      "Boost your team's productivity by efficient collaboration on research projects. Upgrade to our Enterprise Plan and revolutionize the way your team works together.",
    pictures: {
      before: "f5.png",
      after: "team-collab-hover.png",
    },
  },
];

export const PRICING_PLANS = [
  {
    id: "free-12345678",
    plan_name: "Free",
    plan_price: 0,
    color_theme: ["#000000", "gray"],
    offers: [
      {
        offer_name: "Monthly searches",
        offers: [
          {
            offer: "Limited 20",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Access to data",
        offers: [
          {
            offer: "Limited access",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Support",
        offers: [
          {
            offer: "Limited email support",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Team Space",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
      {
        offer_name: "Access to API",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
    ],
    description: "Restricted access to the core functionalities and data bases",
    discount: 100,
  },
  {
    id: "66a6d53e466ab49f0a6fda10",
    plan_name: "Basic",
    plan_price: 50,
    color_theme: ["#4880c8", "blue"],
    offers: [
      {
        offer_name: "Monthly searches",
        offers: [
          {
            offer: "Limited 100",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Access to data",
        offers: [
          {
            offer: "Full access to core molecules data",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Support",
        offers: [
          {
            offer: "Email support",
            type: "limited",
          },
        ],
      },
      {
        offer_name: "Team Space",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
      {
        offer_name: "Access to API",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
    ],
    description: "Limited access to the core functionalities and data bases",
    discount: 60,
  },
  {
    id: "66a6d979466ab49f0a6fda12",
    plan_name: "Premium",
    plan_price: 250,
    color_theme: ["#fcba03", "yellow"],
    offers: [
      {
        offer_name: "Monthly searches",
        offers: [
          {
            offer: "Unlimited",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Access to data",
        offers: [
          {
            offer:
              "Full access to core data and extra layers (initially mRNA lake)",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Support",
        offers: [
          {
            offer: "Priority email support",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Team Space",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
      {
        offer_name: "Access to API",
        offers: [
          {
            offer: "Not supported",
            type: "restricted",
          },
        ],
      },
    ],
    description: "Wider access to core functionalities, databases and models",
    discount: 60,
  },
  {
    id: "66a6d998466ab49f0a6fda13",
    plan_name: "Enterprise",
    plan_price: 250,
    color_theme: ["#c54b8a", "pink"],
    offers: [
      {
        offer_name: "Monthly searches",
        offers: [
          {
            offer: "Unlimited",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Access to data",
        offers: [
          {
            offer:
              "Full access to core data and extra layers (initially mRNA lake) and early access to test new layers",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Support",
        offers: [
          {
            offer:
              "Priority email support and possibility to schedule an appointment",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Team Space",
        offers: [
          {
            offer: "Available team space to collaborate and share content",
            type: "unlimited",
          },
        ],
      },
      {
        offer_name: "Access to API",
        offers: [
          {
            offer:
              "Available API to consume data, models, and our research assistant",
            type: "unlimited",
          },
        ],
      },
    ],
    description:
      "Unlimited access to all functionalities, databases, models and team collaboration",
    discount: 50,
  },
];

export const SUPPORTED_CURRENCIES = [
  "usd",
  "aed",
  "afn",
  "all",
  "amd",
  "ang",
  "aoa",
  "ars",
  "aud",
  "awg",
  "azn",
  "bam",
  "bbd",
  "bdt",
  "bgn",
  "bif",
  "bmd",
  "bnd",
  "bob",
  "brl",
  "bsd",
  "bwp",
  "byn",
  "bzd",
  "cad",
  "cdf",
  "chf",
  "clp",
  "cny",
  "cop",
  "crc",
  "cve",
  "czk",
  "djf",
  "dkk",
  "dop",
  "dzd",
  "egp",
  "etb",
  "eur",
  "fjd",
  "fkp",
  "gbp",
  "gel",
  "gip",
  "gmd",
  "gnf",
  "gtq",
  "gyd",
  "hkd",
  "hnl",
  "hrk",
  "htg",
  "huf",
  "idr",
  "ils",
  "inr",
  "isk",
  "jmd",
  "jpy",
  "kes",
  "kgs",
  "khr",
  "kmf",
  "krw",
  "kyd",
  "kzt",
  "lak",
  "lbp",
  "lkr",
  "lrd",
  "lsl",
  "mad",
  "mdl",
  "mga",
  "mkd",
  "mmk",
  "mnt",
  "mop",
  "mur",
  "mvr",
  "mwk",
  "mxn",
  "myr",
  "mzn",
  "nad",
  "ngn",
  "nio",
  "nok",
  "npr",
  "nzd",
  "pab",
  "pen",
  "pgk",
  "php",
  "pkr",
  "pln",
  "pyg",
  "qar",
  "ron",
  "rsd",
  "rub",
  "rwf",
  "sar",
  "sbd",
  "scr",
  "sek",
  "sgd",
  "shp",
  "sle",
  "sos",
  "srd",
  "std",
  "szl",
  "thb",
  "tjs",
  "top",
  "try",
  "ttd",
  "twd",
  "tzs",
  "uah",
  "ugx",
  "uyu",
  "uzs",
  "vnd",
  "vuv",
  "wst",
  "xaf",
  "xcd",
  "xof",
  "xpf",
  "yer",
  "zar",
  "zmw",
];

// List of use cases
export const useCases: UseCase[] = [
  {
    title: "mRNA Research for Vaccine Development",
    description: `Lakesai enhances your mRNA research for vaccine development by providing powerful tools 
for data mining and sequence design. Effortlessly search literature, mine comprehensive data on gene 
expression profiles and sequence variations, and predict the efficacy and stability of mRNA sequences. 
`,
    colors: ["secondary.500", "secondary.600"],
    icon: PiDna,
  },
  {
    title: "Research in Sickle Cell Therapeutics",
    description: `Lakesai empowers your research into sickle cell disease by providing advanced tools to identify compounds that prevent red blood cell sickling and inhibit hemoglobin aggregation. Map how each 
compound influences critical cellular pathways, enhancing your research precision.
`,
    colors: ["teal.300", "teal.400"],
    icon: TbCell,
  },
  {
    title: "Neuropsychiatric Conditions Research",
    description: `Accelerate your research into neuropsychiatric conditions related to infectious diseases using 
Lakesai's advanced tools. Effortlessly identify therapeutic compounds, analyze their molecular 
interactions, and explore their impact on neuropsychiatric symptoms.
`,
    colors: ["indigo.300", "indigo.400"],
    icon: PiBrain,
  },
];
