import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { NotificationsResponse } from "models/notifications/NotificationProps";

export const useNotificationsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const getNotifications = async ({ pageParam = 1 }: { pageParam: number }): Promise<NotificationsResponse> => {
    const response = await axiosPrivate.get(`/api/notifications`, {
      params: {
        page: pageParam,
      }
    });
    return response.data;
  };

  const markNotificationsAsRead = async () => {
    const response = await axiosPrivate.put(`/api/notifications`);
    return response.data;
  };

  const removeNotification = async (id: string) => {
    const response = await axiosPrivate.delete(`/api/notification/${id}`);
    return response.data;
  };

  return { getNotifications, markNotificationsAsRead, removeNotification };
};
