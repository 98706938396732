import { Box, Image } from "@chakra-ui/react";

export default function Promo() {
  return (
    <Box
      mx={"auto"}
      w={"95%"}
      maxW={"1000px"}
      p={{ base: 0, lg: "20px" }}
      boxShadow={"lg"}
      borderRadius={{ base: "16px", lg: "36px" }}
      overflow={"hidden"}
      zIndex={3}
      my={"80px"}
      h={"fit-content"}
    >
      <Image
        borderRadius={"12px"}
        src={
          "https://lakesai.s3.eu-central-1.amazonaws.com/assistant-overview.png"
        }
        alt={"Sai assistant overview"}
        w={"3024px"}
        h={"1714px"}
        style={{ width: "100%", height: "100%" }}
        loading={"lazy"}
      />
    </Box>
  );
}
