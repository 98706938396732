import { Box, Flex, Stack, Text } from "@chakra-ui/react";

const SessionsLoadingIndicator = () => {
    const dotAnimation = `bounce 1.4s infinite ease-in-out`;
    return (
        <Box px={3} py={2} mt={-2} mb={2}>
            <Flex align="center" justifyContent={'center'} gap={2}>
                <Stack spacing={1} direction="row">
                    {[0, 0.32, 0.16].map((delay, index) => (
                        <Box
                            key={index}
                            w="2px"
                            h="2px"
                            borderRadius="full"
                            bg="blue.400"
                            sx={{
                                animation: dotAnimation,
                                animationDelay: `-${delay}s`,
                                '@keyframes bounce': {
                                    '0%, 80%, 100%': {
                                        transform: 'scale(0)',
                                    },
                                    '40%': {
                                        transform: 'scale(1.0)',
                                    },
                                },
                            }}
                        />
                    ))}
                </Stack>
                <Text
                    fontSize="xs"
                    color="gray.500"
                    fontStyle="italic"
                >
                    Loading sessions
                </Text>
            </Flex>
        </Box>
    );
};

export default SessionsLoadingIndicator