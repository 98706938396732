import { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

import {
  Flex,
  Text,
  useMediaQuery,
  usePrefersReducedMotion,
  useTheme,
} from "@chakra-ui/react";

import Countdown from "./Countdown";
import { getTimeRemaining, TimeRemaining } from "views/home/helpers";

import { keyframes } from "@emotion/react";

// Promo expired at midnight 15th of october
const PromoDeadline = "2024-11-01T00:00:00";

const bgPosition = keyframes`
  from { background-position: 0% center; }
  to { background-position: -200% center; }`;

function Promotion() {
  // States
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    getTimeRemaining(PromoDeadline)
  );

  // Theme
  const { colors } = useTheme();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const bgPositionAnimation = prefersReducedMotion
    ? undefined
    : `${bgPosition} 3s linear infinite`;

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining(PromoDeadline));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { days, hours, minutes, seconds } = timeRemaining;
  if (!seconds && !minutes && !hours && !days) return <></>;

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      align={"center"}
      gap={{ base: 4, lg: 4 }}
      h={"fit-content"}
      my={{ base: 8, lg: 0 }}
    >
      {/* title */}
      <Flex
        align={"center"}
        gap={1.5}
        position={"relative"}
        fontSize={{ base: "sm", md: "md", xl: "xl" }}
      >
        <Text
          textAlign={{ base: "center", lg: "left" }}
          fontWeight={{ base: "500", lg: "400" }}
          color={{ base: "secondary.700", lg: "whiteAlpha.900" }}
          fontFamily={{ base: "Poppins, sans-serif", lg: "inherit" }}
        >
          Don't miss out on our
        </Text>
        <Text
          background={
            isMobileView
              ? `linear-gradient(to right, ${colors.secondary[700]} 50%,${colors.highlight.primary}, ${colors.secondary[700]} 75%)`
              : `linear-gradient(to right, white 50%, ${colors.blue[600]}, white 75%)`
          }
          animation={bgPositionAnimation}
          backgroundSize={"200%"}
          whiteSpace="nowrap"
          fontWeight={"500"}
          bgClip={"text"}
          textTransform={"capitalize"}
          position="relative"
        >
          <Link to={"#pricing"} smooth>
            Special promo!
          </Link>
        </Text>
      </Flex>

      {/* Countdown */}
      <Countdown timeRemaining={timeRemaining} />
    </Flex>
  );
}

export default Promotion;
