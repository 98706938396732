import React, { forwardRef, useRef, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { chakra, BoxProps } from "@chakra-ui/react";


export const CustomScrollBarLighterBg = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.300",
    },
    // remove black border on focus and element is visibly indicated to the user
    // apply style for main scrollbar element & his children
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
    {
      border: "none",
      outline: "none",
    },
  },
});

export const CustomScrollBar = chakra(SimpleBar, {
  baseStyle: {
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
    {
      border: "none",
      outline: "none",
    },
  },
});

// export const CustomThickScrollBar = chakra(SimpleBar, {
//   baseStyle: {
//     "& .simplebar-track": {
//       width: "8px",
//     },
//     "& .simplebar-scrollbar::before": {
//       backgroundColor: "gray.400",
//     },
//     "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible":
//       {
//         border: "none",
//         outline: "none",
//       },
//   },
// });


interface ThickScrollBarProps extends BoxProps {
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

const ThickScrollBar = forwardRef<any, ThickScrollBarProps>(
  ({ onScroll, children, ...props }, ref) => {

    const simpleBarRef = useRef<any>(null);
    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // Get the actual scrollable element
      if (simpleBarRef.current) {
        scrollElementRef.current = simpleBarRef.current.getScrollElement();

        const scrollElement = scrollElementRef.current;
        if (scrollElement && onScroll) {
          scrollElement.addEventListener('scroll', onScroll as any);

          return () => {
            scrollElement.removeEventListener('scroll', onScroll as any);
          };
        }
      }
    }, [onScroll]);

    return (
      <SimpleBar
        ref={(el) => {
          simpleBarRef.current = el;
          // Support forwarded ref
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref && 'current' in ref) {
            (ref as React.MutableRefObject<any>).current = el;
          }
        }}
        {...props as any}
      >
        {children}
      </SimpleBar>
    );
  }
);

// Apply Chakra UI styling
export const CustomThickScrollBar = chakra(ThickScrollBar, {
  baseStyle: {
    "& .simplebar-track": {
      width: "8px",
    },
    "& .simplebar-scrollbar::before": {
      backgroundColor: "gray.400",
    },
    "& .simplebar-content-wrapper:focus-visible, & .simplebar-content-wrapper *:focus-visible": {
      border: "none",
      outline: "none",
    },
  },
});