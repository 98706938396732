import { Fragment, MouseEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import {
  Flex,
  Text,
  Icon,
  useTheme,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuDivider,
  useClipboard,
} from "@chakra-ui/react";

import { IconType } from "react-icons";
import { hexToRgba } from "utils/helpers";

import { SlOptions } from "react-icons/sl";
import { RiEdit2Fill } from "react-icons/ri";
import { MdFileDownload } from "react-icons/md";
import { IoCheckmarkOutline } from "react-icons/io5";
import { HiTrash, HiClipboard } from "react-icons/hi";

interface ChatItemProps {
  id: string;
  title: string;
  isSelected: boolean;
  isDisabled: boolean;
  onDeleteSession: (id: string) => void;
  onExportChat: (id: string) => void;
  onUpdateChatTitle: (id: string) => void;
}

interface MenuItemProps {
  name: string;
  icon: IconType;
  type: "copy" | "rename" | "export" | "delete";
  onClick: (e: MouseEvent) => void;
}

export default function ChatItem({
  id,
  title,
  isSelected,
  isDisabled,
  onExportChat,
  onDeleteSession,
  onUpdateChatTitle,
}: ChatItemProps) {
  // Hooks
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasCopied, onCopy } = useClipboard(title);

  // State
  const [showMenuButton, setShowMenuButton] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgColor = isLight ? "neutral.200" : "lightBackground";

  const menuItems: MenuItemProps[] = useMemo(
    () => [
      {
        icon: hasCopied ? IoCheckmarkOutline : HiClipboard,
        name: hasCopied ? "Copied" : "Copy title",
        type: "copy",
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          onCopy();
        },
      },
      {
        icon: RiEdit2Fill,
        name: "Rename title",
        type: "rename",
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          onUpdateChatTitle(id);
        },
      },
      {
        icon: MdFileDownload,
        name: "Export chat",
        type: "export",
        onClick: (e: MouseEvent) => {
          e.stopPropagation(); // Prevent the session from being selected
          queryClient.setQueryData(["sessionTitle"], title);
          onExportChat(id);
        },
      },
      {
        icon: HiTrash,
        name: "Delete chat",
        type: "delete",
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          onDeleteSession(id);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasCopied, id, onCopy, onDeleteSession, onUpdateChatTitle]
  );

  const handleNavigateToChat = (chatTitle: string) => {
    queryClient.setQueryData(["sessionTitle"], chatTitle);
    navigate("chat/" + id);
  };

  useEffect(() => {
    if (isSelected) {
      queryClient.setQueryData(["sessionTitle"], title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return (
    <Flex
      my={"1px"}
      position={"relative"}
      align={"center"}
      justify={"space-between"}
      rounded={"xs"}
      cursor={"pointer"}
      fontWeight={isSelected ? "500" : "400"}
      letterSpacing={isSelected ? "-0.012rem" : "0"}
      pointerEvents={isDisabled ? "none" : "auto"}
      borderTopWidth={1}
      borderBottomWidth={1}
      bg={isSelected ? bgColor : "transparent"}
      borderColor={"transparent"}
      transition={"border .3s ease"}
      _hover={{ bg: bgColor }}
      onClick={() => handleNavigateToChat(title)}
      onMouseOver={() => setShowMenuButton(true)}
      onMouseLeave={() => setShowMenuButton(false)}
      borderRadius={"6px"}
    >
      {/* chat header */}
      <Text
        fontSize={{ lg: "12px", xl: "13px" }}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        color={"gray.600"}
        lineHeight={"1.3"}
        p={1.5}
      >
        {title}
      </Text>

      {/* chat options */}
      <Menu placement={"top-end"} isLazy>
        <MenuButton
          display={"flex"}
          alignItems={"center"}
          w={"fit-content"}
          h={"100%"}
          cursor={"default"}
          borderRadius={"6px"}
          borderTopStartRadius={0}
          borderBottomStartRadius={0}
          color={"gray.500"}
          _hover={{ color: "highlight.primary", transition: "all 0.3s ease" }}
          transition={"all 0.3s ease"}
          onClick={(e) => e.stopPropagation()}
          pr={1}
        >
          {showMenuButton ? (
            <Icon
              display={"flex"}
              bg={"transparent"}
              as={SlOptions}
              boxSize={5}
              ml={"auto"}
              p={1}
              borderRadius={"60%"}
            />
          ) : (
            <Flex h={"20px"} /> // NOTE: placeholder to preserve menu relative position on scroll
          )}
        </MenuButton>

        <MenuList
          bg={"background"}
          borderColor={hexToRgba(colors.secondary[700], 0.2)}
          borderRadius={"6px"}
          zIndex={999}
          w={"160px"}
          minW={"160px"}
          h={"fit-content"}
          m={0}
          p={2}
          boxShadow={"md"}
        >
          {menuItems?.map((item, index) => {
            const isCopyItem = item?.type === "copy";
            const isExportItem = item?.type === "export";
            const isDeleteItem = item?.type === "delete";

            return (
              <Fragment key={"item-" + index}>
                {isDeleteItem && <MenuDivider />}
                {isExportItem ? (
                  <MenuItem
                    icon={
                      <Icon
                        as={item?.icon}
                        boxSize={4}
                        color={isDeleteItem ? "red.600" : "gray.600"}
                      />
                    }
                    iconSpacing={2}
                    display={"flex"}
                    alignItems={"center"}
                    px={1}
                    py={2}
                    color={isDeleteItem ? "red.500" : "gray.500"}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                    pointerEvents={
                      (isCopyItem && hasCopied) || (isDeleteItem && isDisabled)
                        ? "none"
                        : "auto"
                    }
                    bg={"background"}
                    _hover={{
                      bg: isDeleteItem
                        ? hexToRgba(colors.red[500], 0.25)
                        : "lightBackground",
                    }}
                    onClick={(e: MouseEvent) => item?.onClick(e)}
                  >
                    <Text fontSize={"14px"}>{item?.name}</Text>
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={
                      <Icon
                        as={item?.icon}
                        boxSize={4}
                        color={isDeleteItem ? "red.600" : "gray.600"}
                      />
                    }
                    iconSpacing={2}
                    display={"flex"}
                    alignItems={"center"}
                    px={1}
                    py={2}
                    color={isDeleteItem ? "red.500" : "gray.500"}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                    pointerEvents={
                      (isCopyItem && hasCopied) || (isDeleteItem && isDisabled)
                        ? "none"
                        : "auto"
                    }
                    bg={"background"}
                    _hover={{
                      bg: isDeleteItem
                        ? hexToRgba(colors.red[500], 0.25)
                        : "lightBackground",
                    }}
                    onClick={item?.onClick}
                  >
                    <Text fontSize={"14px"}>{item?.name}</Text>
                  </MenuItem>
                )}
              </Fragment>
            );
          })}
        </MenuList>
      </Menu>
    </Flex>
  );
}
