import { useToast } from "@chakra-ui/react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useNotificationsAPI } from "api/notifications/useNotificationsAPI";
import { NotificationsResponse } from "models/notifications/NotificationProps";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { addNotification, setError, setNotifications, setUnreadCount } from "redux/features/notifications/notificationsSlice";

const useNotificationsWS = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { user } = useSelector(selectCurrentAuthData);
  const { getNotifications } = useNotificationsAPI();
  const queryClient = useQueryClient();

  const reconnectInterval = useRef<NodeJS.Timeout | null>(null);
  const notificationsWebSocket = useRef<WebSocket | null>(null);

  const { data, error } = useInfiniteQuery<NotificationsResponse, Error, InfiniteData<NotificationsResponse>, [string], number>({
    queryKey: ["notifications"],
    queryFn: ({ pageParam }) => getNotifications({ pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage.has_next ? lastPage.current_page + 1 : undefined,
    initialPageParam: 1,
    enabled: !!user,
    staleTime: 60000,
    retry: 1
  });

  useEffect(() => {
    if (data) {
      const allNotifications = data.pages.flatMap(page => page.notifications);
      dispatch(setUnreadCount(data.pages[0].unread_count));
      dispatch(setNotifications(allNotifications));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setError(error.message));
    }
  }, [error, dispatch]);

  const handleConnection = () => {
    if (!user || notificationsWebSocket.current?.readyState === WebSocket.OPEN) return;

    const HOST_NAME = window.location.hostname;
    const HOST =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "ws://127.0.0.1:8080"
        : HOST_NAME.trim() === "lakesai.vitafluence.com"
          ? "wss://mdpp.vitafluence.com"
          : "wss://mdps.vitafluence.com";

    const WS_URL = `${HOST}/ws/notifications/${user?.id}/`;

    notificationsWebSocket.current = new WebSocket(WS_URL);

    notificationsWebSocket.current.onopen = () => {
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    notificationsWebSocket.current.onmessage = async (event: MessageEvent) => {
      const eventData = JSON.parse(event.data);
      const { notification } = eventData;
      const { type, message, title } = notification;

      dispatch(addNotification(notification));

      toast({
        title: `${type.charAt(0).toUpperCase()}${type.slice(1)}: ${title}`,
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      const firstPage = await getNotifications({ pageParam: 1 });

      queryClient.setQueryData(["notifications"], {
        pages: [firstPage],
        pageParams: [1]
      });

      if (firstPage.has_next) {
        queryClient.setQueryData(["notifications"], (oldData: any) => ({
          ...oldData,
          hasNextPage: true
        }));
      }
    };

    notificationsWebSocket.current.onerror = (error) => {
      notificationsWebSocket.current?.close();
      attemptReconnect();
    };

    notificationsWebSocket.current.onclose = () => {
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  };

  const attemptReconnect = () => {
    if (!reconnectInterval.current) {
      reconnectInterval.current = setInterval(() => {
        handleConnection();
      }, 5000);
    }
  };

  useEffect(() => {
    if (!user) return;

    handleConnection();

    return () => {
      if (notificationsWebSocket.current) {
        notificationsWebSocket.current.close();
        notificationsWebSocket.current = null;
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };
  }, [user]);


  return notificationsWebSocket
};

export default useNotificationsWS;
