import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  selectCurrentPostsData,
  setTeamData,
} from "redux/features/posts/postsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import {
  ColumnOrderState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useClipboard,
  useToast,
} from "@chakra-ui/react";

import { useTeamsAPI } from "api/useTeamsAPI";
import { useDataLibraryAPI } from "api/useDataLibraryAPI";

import DataSearchInput from "./SearchInput";
import Loading from "components/ui/Loading";
import DeleteModal from "components/ui/DeleteModal";
import MainPanelError from "components/ui/MainPanelError";
import NoResultsFound from "components/ui/NoResultsFound";
import RateLimitModal from "components/ui/RateLimitModal";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import { CoreContext } from "views/library/core";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { TYPES } from "utils/premissions";

import {
  SourceProps,
  returnValueAsAnArray,
  getImageSrc,
  getValueByAccessor,
  TableColumn,
  ProprietarySourceProps,
} from "../../../views/library/helpers";
import { errorHandler, isWithinLimit } from "utils/helpers";
import { PaperProps } from "models/papers/PaperProps";
import {
  handleDragEnd,
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleFilterClear,
  handleRowClick,
  handleFilterInputKeyDown,
  handleFilterIconClick,
  handleFilterInputChange,
} from "./helpers";

import CustomLoading from "./ui/CustomLoading";
import { CustomOverlay } from "./ui/CustomOverlay";
import NewSourceModal from "../proprietary/AddNewSourcesModal";

import {
  FaFilter,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
} from "react-icons/fa";
import { DeleteIcon } from "@chakra-ui/icons";
import { AiOutlineFileAdd } from "react-icons/ai";
import { indicationToColor } from "components/ui/helpers";
import { FaCheck } from "react-icons/fa";
import { BiSolidCopy } from "react-icons/bi";

const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
};

export interface AgnosticDataDisplayProps {
  source: SourceProps | ProprietarySourceProps;
}

export interface ColumnFiltersProp {
  [key: string]: string;
}
type RowDetailsType = {
  sequence?: string;
};

const AgnosticDataDisplay = ({ source }: AgnosticDataDisplayProps) => {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const page = useRef<number>(1);
  const { layerName } = useParams();
  const { getTeam } = useTeamsAPI();
  const inputRefs = useRef(new Map());
  const queryClient = useQueryClient();
  const sort = useRef<string | null>(null);
  const fetchedAll = useRef<boolean>(false);
  const order = useRef<"desc" | "asc" | null>(null);
  const { user } = useSelector(selectCurrentAuthData);
  const { teamData } = useSelector(selectCurrentPostsData);
  const { searchState, setSearchState, results, setResults } =
    useContext(CoreContext);
  const { fetchTableData, fetchProprietaryTableData, fetchMRNATableData } =
    useDataLibraryAPI();

  const { deleteProprietaryPaper } = useDataLibraryAPI();

  const { key, table } = source;
  const { columns } = table;

  const location = useLocation();
  const pathName = location.pathname;

  // States
  const [error, setError] = useState<string>("");
  const [paperTitle, setPaperTitle] = useState("");
  const [hoveredRow, setHoveredRow] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [selectedPaperId, setSelectedPaperId] = useState("");
  const [isFetching, setFetching] = useState<boolean>(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [filters, setFilters] = useState(searchState.filters);
  const [sorting, setSorting] = useState(searchState.sorting);
  const [deletingFile, setDeletingFile] = useState<boolean>(false);
  const [showNewSourceModal, setShowNewSourceModal] = useState(false);
  const [searchValue, setSearchValue] = useState(searchState.searchValue);
  const [showDeletePaperModal, setShowDeletePaperModal] = useState(false);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    table.columns.map((col) => col.accessor)
  );
  const [columnsFilters, setColumnsFilters] = useState(
    searchState.columnsFilters
  );
  const [rowDetails, setRowDetails] = useState<RowDetailsType>({});
  const { onCopy, hasCopied } = useClipboard(rowDetails?.sequence || "");
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const itemsPerPage = 20;

  useEffect(() => {
    if (!teamData && pathName === "/team/literature") {
      navigate("/team");
    }
    return () => {
      isMounted.current = false; // Set to false when the component is unmounted
      if (abortController) abortController.abort();
    };
  }, [abortController, navigate, pathName, teamData]);

  useEffect(() => {
    setSearchState({ searchValue, sorting, filters, columnsFilters });
  }, [searchValue, sorting, filters, setSearchState, columnsFilters]);

  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottom = scrollHeight - scrollTop <= clientHeight * 1.5;

    if (bottom && !isFetching && page.current && !fetchedAll.current) {
      setFetching(true);
      page.current += 1;
      try {
        const controller = new AbortController();
        setAbortController(controller);

        const source_key =
          layerName === "Private" || pathName === "/team/literature"
            ? "LITERATURE"
            : key;
        const data = await queryClient.fetchQuery({
          queryKey: [
            `${key}-table`,
            {
              query: searchValue,
              page: page.current,
              itemsPerPage,
              source: source_key,
              order: order.current,
              sort: sort.current,
              filters,
            },
          ],
          queryFn: ({ queryKey }) =>
            layerName === "Private" || pathName === "/team/literature"
              ? fetchProprietaryTableData({
                  queryKey,
                  signal: controller.signal,
                })
              : layerName === "mRNALake"
              ? fetchMRNATableData({ queryKey, signal: controller.signal })
              : fetchTableData({ queryKey, signal: controller.signal }),
          staleTime: 2 * 1000 * 60,
          retry: false,
        });

        setResults((prevData) =>
          prevData ? [...prevData, ...data.data] : data.data
        );
        fetchedAll.current = !data.hasMore;
      } catch (error: any) {
        if (error.name !== "CanceledError") {
          setError(errorHandler(error).message);
        } else {
          isMounted.current && fetchData();
        }
      } finally {
        setFetching(false);
      }
    }
  };

  // Table Instance
  const columnHelper = createColumnHelper<TableColumn>();
  const tableColumns = useMemo(
    () =>
      table.columns.map((col: TableColumn) =>
        columnHelper.accessor((row: { accessor: any }) => row.accessor, {
          id: col.accessor,
          header: col.header,
          enableSorting: !!col.sortable ? true : false,
          enableColumnFilter: !!col.filterable ? true : false,
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.columns]
  );

  const tableInstance = useReactTable({
    columns: tableColumns,
    data: results ?? [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnOrder,
      columnVisibility: {
        id: false,
      },
    },
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
  });

  // Table Search
  const clearSearch = () => {
    // Cancel ongoing request
    if (abortController) abortController.abort();

    setSearchValue("");
    page.current = 1;
    sort.current = null;
    order.current = null;
  };

  const submitSearchForm = (inputStr: string) => {
    if (loading || isFetching) return;
    clearSearch();
    setFilters({});
    setColumnsFilters({ showFilter: null, filters: {} });
    setSearchValue(inputStr);
  };

  // Sorting
  const fetchData = async () => {
    setLoading(true);
    try {
      const controller = new AbortController();
      setAbortController(controller);

      const source_key =
        layerName === "Private" || pathName === "/team/literature"
          ? "LITERATURE"
          : key;

      const data = await queryClient.fetchQuery({
        queryKey: [
          `${key}-table`,
          {
            query: searchValue,
            page: page.current,
            itemsPerPage,
            source: source_key,
            order: order.current,
            sort: sort.current,
            filters,
          },
        ],
        queryFn: ({ queryKey }) =>
          layerName === "Private" || pathName === "/team/literature"
            ? fetchProprietaryTableData({
                queryKey,
                signal: controller.signal,
              })
            : layerName === "mRNALake"
            ? fetchMRNATableData({ queryKey, signal: controller.signal })
            : fetchTableData({ queryKey, signal: controller.signal }),
        staleTime: Infinity, // always fresh since it rarely changes
        gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
        retry: false,
      });
      setResults(data.data);
      fetchedAll.current = !data.hasMore;
      if (data.data.length === 0) fetchedAll.current = !data.hasMore;
    } catch (error: any) {
      if (error?.response?.status === 403 && layerName === "Private") {
        toast({
          description:
            "You do not permissions to access this source. Redirecting to public sources ....",
          status: "warning",
          position: "top-right",
        });
        navigate(`/data/core/MoleculeLake`);
      }
      if (error.name !== "CanceledError") {
        setError(errorHandler(error).message);
      } else {
        isMounted.current && fetchData();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, key, itemsPerPage, filters]);

  useEffect(() => {
    if (sorting.length === 0) return;

    const tempOrder = sorting[0]?.desc ? "desc" : "asc";
    const tempSort = sorting[0]?.id ?? "id";
    order.current = tempOrder;
    sort.current = tempSort;

    page.current = 1;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const { isHighlighted } = useGuideTour();
  let sortHighlighted = isHighlighted("core_table-column-sort");
  let filterHighlighted = isHighlighted("core_table-column-filter");
  let rowHighlighted = isHighlighted("core_table-row");
  let sortInfoDisplayed = false;
  let filterInfoDisplayed = false;

  const contentHeight = useBreakpointValue({
    lg: "calc(100vh - 156px)", // 150 = (8x2) + 62 + 24 + 32 + 16 : py + nav + gap + breadcrumb
    xl: "calc(100vh - 164px)", // 158 = (12x2) + 62 + 24 + 32 + 16
    "2xl": "calc(100vh - 180px)", // 174 = (24x2) + 62 + 24 + 32 + 16
  });
  const handleAddNewSource = () => {
    setShowNewSourceModal(true);
  };

  const closeModal = () => {
    setShowNewSourceModal(false);
    queryClient.invalidateQueries({ queryKey: ["PROPRIETARY-table"] });
    fetchData();
  };

  const { data } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: false,
    enabled: user?.user_type === TYPES.TEAMS,
  });

  const handleDeleteClick = (paperInfo: any) => {
    let title = paperInfo.title;
    const paperId = paperInfo.id;
    setSelectedPaperId(paperId);
    if (paperInfo.title.endsWith(".pdf")) {
      title = title.slice(0, -4);
    }
    setPaperTitle(title);
    setShowDeletePaperModal(true);
  };

  // Function called when the user confirms the deletion
  const handleConfirmDeletePaper = async () => {
    setDeletingFile(true);
    setSelectedPaperId("");
    try {
      await deleteProprietaryPaper(selectedPaperId);
      const source_key =
        layerName === "Private" || pathName === "/team/literature"
          ? "LITERATURE"
          : key;
      const queryKey = [
        `PROPRIETARY-table`,
        {
          query: searchValue,
          page: page.current,
          itemsPerPage,
          source: source_key,
          order: order.current,
          sort: sort.current,
          filters,
        },
      ];

      const oldData = queryClient.getQueryData<{ data: PaperProps[] }>(
        queryKey
      );
      if (oldData && oldData.data) {
        const updatedData = oldData.data.filter(
          (paper: PaperProps) => paper.id !== selectedPaperId
        );
        queryClient.setQueryData(queryKey, {
          ...oldData,
          data: updatedData,
        });
        setResults(updatedData);
      }
      toast({
        description: "Paper deleted successfully",
        status: "success",
        position: "top-right",
      });
    } catch (error) {
      toast({
        description: "Failed to delete paper",
        status: "error",
        position: "top-right",
      });
    } finally {
      setDeletingFile(true);
      handleCloseDeletePaperModal();
    }
  };

  const handleCloseDeletePaperModal = () => {
    setShowDeletePaperModal(false);
    setSelectedPaperId("");
  };

  const handleMouseEnter = (row: any) => {
    setHoveredRow(row.id);
    setShowDeleteIcon(
      layerName === "Private" || pathName === "/team/literature" ? true : false
    );
    setRowDetails(row?.original as any);
  };
  const handleCopy = async () => {
    onCopy();
  };

  useEffect(() => {
    if (data) dispatch(setTeamData(data));
  }, [data, dispatch]);

  return (
    <Flex
      direction={"column"}
      gap={3}
      h={contentHeight}
      overscrollBehaviorX={"none"}
      overflow={"hidden"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <DataSearchInput
          submitSearchForm={submitSearchForm}
          clearSearch={clearSearch}
          searchVal={searchValue}
        />
        {(layerName === "Private" || pathName === "/team/literature") && (
          <Button
            maxW={"100%"}
            size="md"
            bg={"highlight.primary"}
            color={"neutral.100"}
            letterSpacing={".03rem"}
            fontSize={"16px"}
            borderRadius={"8px"}
            p={4}
            _hover={{ opacity: 0.9 }}
            _active={{ opacity: 0.9 }}
            onClick={handleAddNewSource}
          >
            <Icon as={AiOutlineFileAdd} mr={2} boxSize={5} />
            Add new source
          </Button>
        )}
      </Flex>

      <TableContainer
        style={{ position: "relative" }}
        zIndex={
          sortHighlighted || filterHighlighted || rowHighlighted ? 1300 : 1
        }
      >
        <CustomScrollBar
          style={tableStyle}
          onScrollCapture={handleScroll}
          zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}
        >
          <Table variant="simple" size="sm">
            <Thead
              position={"sticky"}
              zIndex={sortHighlighted || filterHighlighted ? 1300 : 1}
              top={0}
              bg={"gray.100"}
              boxShadow={"none"}
            >
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} position={"relative"}>
                  {headerGroup.headers.map((header, index) => {
                    const canSort = header.column.getCanSort();
                    const isSorted = header.column.getIsSorted();
                    const canFilter = header.column.getCanFilter();
                    const showFilter =
                      columnsFilters.showFilter === header.id ||
                      !!columnsFilters.filters[header.id];

                    let SortInfo = !sortInfoDisplayed;
                    let FilterInfo = !filterInfoDisplayed;

                    if (canSort) {
                      sortInfoDisplayed = true;
                    }
                    if (canFilter) {
                      filterInfoDisplayed = true;
                    }

                    return (
                      <Th
                        key={header.id}
                        boxShadow={"none"}
                        py={2}
                        px={1}
                        textAlign={"left"}
                        border={"none"}
                        borderLeftRadius={index === 0 ? "md" : "none"}
                        borderRightRadius={
                          index === headerGroup.headers.length - 1
                            ? "md"
                            : "none"
                        }
                        borderRight={
                          index !== headerGroup.headers.length - 1
                            ? "1px solid"
                            : "none"
                        }
                        borderRightColor={"gray.200"}
                        draggable
                        onDragStart={(e) => handleDragStart(e, header.id)}
                        onDrop={(e) =>
                          handleDrop(e, header.id, columnOrder, setColumnOrder)
                        }
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDragEnd={handleDragEnd}
                      >
                        {(sortHighlighted ||
                          filterHighlighted ||
                          rowHighlighted) && <CustomOverlay />}
                        <Flex
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          color={"gray.500"}
                        >
                          {canSort ? (
                            <Box
                              p={1}
                              id={"core_table-column-sort"}
                              zIndex={sortHighlighted && SortInfo ? 1400 : 1}
                              bg={
                                sortHighlighted && SortInfo
                                  ? "background"
                                  : "transparent"
                              }
                              height={"100%"}
                              onClick={header.column.getToggleSortingHandler()}
                              borderRadius={"4px"}
                            >
                              <Flex alignItems={"center"}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {isSorted === "asc" ? (
                                  <FaSortUp style={{ marginLeft: "2px" }} />
                                ) : isSorted === "desc" ? (
                                  <FaSortDown style={{ marginLeft: "2px" }} />
                                ) : (
                                  <FaSort style={{ marginLeft: "2px" }} />
                                )}
                              </Flex>
                            </Box>
                          ) : (
                            <Box>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>
                          )}
                          {canFilter && (
                            <InputGroup
                              width={"fit-content"}
                              justifyContent={"flex-end"}
                              id={"core_table-column-filter"}
                              zIndex={
                                FilterInfo && filterHighlighted ? 1400 : 1
                              }
                              bg={
                                FilterInfo && filterHighlighted
                                  ? "background"
                                  : "transparent"
                              }
                              borderRadius={"4px"}
                            >
                              <Input
                                ref={(el) =>
                                  inputRefs.current.set(header.id, el)
                                }
                                disabled={!!filters[header.id]}
                                value={columnsFilters.filters[header.id] || ""}
                                transition="ease-in-out .4s"
                                width={showFilter ? "100%" : "0"}
                                minWidth={showFilter ? "70px" : "0"}
                                maxWidth={"170px"}
                                pl={showFilter ? 1 : 0}
                                border={showFilter ? "1px solid" : "none"}
                                borderColor={
                                  showFilter ? "inherit" : "transparent"
                                }
                                backgroundColor={
                                  !!filters[header.id] ? "green.100" : "inherit"
                                }
                                pr={"20px"}
                                py={0}
                                h={6}
                                borderRadius={4}
                                size="xs"
                                color={"gray.700"}
                                outline={"none"}
                                onKeyDown={(
                                  e: React.KeyboardEvent<HTMLInputElement>
                                ) =>
                                  handleFilterInputKeyDown(
                                    e,
                                    header.id,
                                    clearSearch,
                                    setFilters
                                  )
                                }
                                onChange={(e) =>
                                  handleFilterInputChange(
                                    e,
                                    header.id,
                                    setColumnsFilters
                                  )
                                }
                                placeholder={`filter ${header.column.columnDef.header}`}
                                _focus={{
                                  boxShadow: "none",
                                }}
                              />
                              <InputRightElement
                                height={"100%"}
                                width={"12px"}
                                right={"5px"}
                              >
                                {columnsFilters.filters[header.id] ? (
                                  <FaTimes
                                    fontSize="12px"
                                    onClick={() =>
                                      handleFilterClear(
                                        header.id,
                                        setColumnsFilters,
                                        setFilters
                                      )
                                    }
                                    cursor={"pointer"}
                                  />
                                ) : (
                                  <FaFilter
                                    fontSize="12px"
                                    onClick={() =>
                                      handleFilterIconClick(
                                        header.id,
                                        setColumnsFilters,
                                        inputRefs
                                      )
                                    }
                                    cursor={"pointer"}
                                  />
                                )}
                              </InputRightElement>
                            </InputGroup>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody position={"relative"}>
              {loading ? (
                <Tr>
                  <Td border={"none"} colSpan={columns.length} py={10}>
                    <CustomLoading
                      sourceName={
                        key.includes("-") ? key.replace(/-/g, " ") : key
                      }
                    />
                  </Td>
                </Tr>
              ) : error ? (
                <Tr>
                  <Td colSpan={columns.length} py={10}>
                    <Flex direction={"column"} alignItems={"center"}>
                      <MainPanelError errorMessage={error} />
                    </Flex>
                  </Td>
                </Tr>
              ) : !results?.length ? (
                <Tr>
                  <Td colSpan={columns.length} py={10}>
                    <Flex direction={"column"} alignItems={"center"}>
                      <NoResultsFound sourceName={key.toLowerCase()} />
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                <>
                  {(sortHighlighted || filterHighlighted) && (
                    <Tr>
                      <Td>
                        <CustomOverlay />
                      </Td>
                    </Tr>
                  )}
                  {tableInstance.getRowModel().rows.map((row) => (
                    <Tr
                      key={"table-row" + row.id}
                      id="core_table-row"
                      zIndex={rowHighlighted ? 1350 : 1}
                      boxShadow={"none"}
                      bg={rowHighlighted ? "background" : "transparent"}
                      py={1}
                      transition="background 0.3s ease"
                      _hover={{ cursor: "pointer", bg: "gray.100" }}
                      onMouseEnter={() => handleMouseEnter(row)}
                      onMouseLeave={() => setShowDeleteIcon(false)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const column = columns.find(
                          (col) => col.accessor === cell.column.id
                        );
                        if (column?.displayFormat === "IMAGE") {
                          return (
                            <Td
                              borderColor={"gray.100"}
                              w={"20%"}
                              key={"table-cell" + cell.id}
                              onClick={() => {
                                // check current user limit
                                if (!isWithinLimit(user)) {
                                  setShowRateModal(true);
                                  return;
                                }
                                handleRowClick(
                                  row.original,
                                  source,
                                  navigate,
                                  toast,
                                  selectedPaperId !== "" ? true : false
                                );
                              }}
                            >
                              <Image
                                src={getImageSrc(row.original, key)}
                                boxSize="100%"
                                maxHeight={"120px"}
                                maxWidth={"120px"}
                                objectFit={"cover"}
                                fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
                                alt={cell.column.id}
                                boxShadow={"md"}
                                borderRadius={"6px"}
                                mx={"auto"}
                              />
                            </Td>
                          );
                        } else if (
                          column?.displayFormat === "TAGS" ||
                          column?.displayFormat === "LIST"
                        ) {
                          const isTag = column?.displayFormat === "TAGS";
                          const tags =
                            returnValueAsAnArray(
                              getValueByAccessor(row.original, cell.column.id)
                            ) || [];
                          return (
                            <Td
                              key={"table-cell" + cell.id}
                              borderColor={"gray.100"}
                              maxW={"25%"}
                              p={1.5}
                              isTruncated
                              onClick={() => {
                                // check current user limit
                                if (!isWithinLimit(user)) {
                                  setShowRateModal(true);
                                  return;
                                }
                                handleRowClick(
                                  row.original,
                                  source,
                                  navigate,
                                  toast,
                                  selectedPaperId !== "" ? true : false
                                );
                              }}
                            >
                              <Flex wrap={"wrap"} gap={1}>
                                {tags.slice(0, 5).map((tag: any, index) => {
                                  const displayedText =
                                    cell.column.id === "authors"
                                      ? `${tag.first_name} ${tag.last_name}`
                                      : tag;
                                  return (
                                    <Tooltip
                                      key={"tag-" + index}
                                      label={displayedText}
                                      bg={"gray.900"}
                                      color={"gray.100"}
                                      hasArrow
                                      py={2}
                                      px={3}
                                      m={2}
                                      maxW={["300px", "360px", "440px"]}
                                      borderRadius={"6px"}
                                      fontSize={"12px"}
                                      boxShadow={"none"}
                                      arrowSize={8}
                                    >
                                      <Tag
                                        color={"gray.900"}
                                        bg={
                                          isTag && cell.column.id !== "authors"
                                            ? indicationToColor(tag)
                                            : "gray.200"
                                        }
                                        fontSize={["10px", "12px", "12px"]}
                                      >
                                        {displayedText.length < 21
                                          ? displayedText
                                          : `${displayedText.slice(0, 20)}..`}
                                      </Tag>
                                    </Tooltip>
                                  );
                                })}
                              </Flex>
                              {tags.length >= 6 && (
                                <Tag
                                  fontStyle={"italic"}
                                  bg={"transparent"}
                                  color={"gray.500"}
                                  fontSize={["10px", "12px", "12px"]}
                                >
                                  {tags.length - 5} more {cell.column.id}..
                                </Tag>
                              )}
                            </Td>
                          );
                        } else if (column?.displayFormat === "BOOL") {
                          const value = getValueByAccessor(
                            row.original,
                            cell.column.id
                          );
                          const NotDefined =
                            value === null || value === undefined;
                          const bgColor = NotDefined
                            ? "gray.300"
                            : value === false
                            ? cell.column.id === "team_access"
                              ? "red.200"
                              : "green.200"
                            : cell.column.id === "team_access"
                            ? "red.200"
                            : "orange.200";

                          const color = NotDefined
                            ? "gray.700"
                            : value === false
                            ? cell.column.id === "team_access"
                              ? "red.500"
                              : "green.500"
                            : cell.column.id === "is_processing"
                            ? "green.600"
                            : "orange.600";

                          const displayText =
                            cell.column.id === "is_open_access"
                              ? NotDefined
                                ? "No info"
                                : value === false
                                ? "Access restricted"
                                : "Open access"
                              : cell.column.id === "team_access"
                              ? NotDefined
                                ? "No info"
                                : value === true
                                ? "Team access"
                                : "Private"
                              : cell.column.id === "is_processing"
                              ? NotDefined
                                ? "No info"
                                : value === true
                                ? "In progress"
                                : "Embeded"
                              : NotDefined
                              ? "no info"
                              : value === false
                              ? "False"
                              : "True";

                          return (
                            <Td
                              key={"table-cell" + cell.id}
                              borderColor={"gray.100"}
                              maxW={"25%"}
                              p={1.5}
                              isTruncated
                            >
                              <Tag
                                fontSize={["10px", "12px", "12px"]}
                                bg={
                                  showDeleteIcon === true &&
                                  cell.column.id === "team_access"
                                    ? ""
                                    : bgColor
                                }
                                color={
                                  showDeleteIcon === true &&
                                  cell.column.id === "team_access"
                                    ? "red.500"
                                    : color
                                }
                                onClick={() =>
                                  layerName === "Private"
                                    ? handleDeleteClick(row.original)
                                    : undefined
                                }
                              >
                                {showDeleteIcon === true &&
                                cell.column.id === "team_access" &&
                                hoveredRow === row.id &&
                                layerName === "Private" ? (
                                  <DeleteIcon />
                                ) : (
                                  `${displayText}`
                                )}
                              </Tag>
                            </Td>
                          );
                        } else if (column?.displayFormat === "NUMBER") {
                          return (
                            <Td
                              key={"table-cell" + cell.id}
                              borderColor={"gray.100"}
                              maxW={"200px"}
                              p={1.5}
                              isTruncated
                              onClick={() => {
                                // check current user limit
                                if (!isWithinLimit(user)) {
                                  setShowRateModal(true);
                                  return;
                                }
                                handleRowClick(
                                  row.original,
                                  source,
                                  navigate,
                                  toast,
                                  selectedPaperId !== "" ? true : false
                                );
                              }}
                            >
                              <Text
                                textTransform={"capitalize"}
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                fontSize={["12px", "14px", "14px"]}
                                fontWeight={"400"}
                              >
                                {getValueByAccessor(
                                  row.original,
                                  cell.column.id
                                )}{" "}
                              </Text>
                            </Td>
                          );
                        } else {
                          return (
                            <Td
                              key={"table-cell" + cell.id}
                              borderColor={"gray.100"}
                              maxW={"350px"}
                              px={1.5}
                              isTruncated
                              _hover={{ cursor: "pointer", bg: "gray.100" }}
                              onMouseEnter={() => handleMouseEnter(row)}
                              onMouseLeave={() => setShowDeleteIcon(false)}
                              onClick={() => {
                                // check current user limit
                                if (!isWithinLimit(user)) {
                                  setShowRateModal(true);
                                  return;
                                }
                                if (hasCopied) {
                                  return;
                                } else {
                                  handleRowClick(
                                    row.original,
                                    source,
                                    navigate,
                                    toast,
                                    selectedPaperId !== "" ? true : false
                                  );
                                }
                              }}
                            >
                              <Flex
                                position={"relative"}
                                display={"flex"}
                                alignContent={"center"}
                                alignItems={"center"}
                                justifyContent={"normal"}
                              >
                                <Text
                                  textTransform={"capitalize"}
                                  whiteSpace={"nowrap"}
                                  overflow={"hidden"}
                                  textOverflow={"ellipsis"}
                                  fontSize={["12px", "14px", "14px"]}
                                  fontWeight={"400"}
                                >
                                  {getValueByAccessor(
                                    row.original,
                                    cell.column.id
                                  )}
                                </Text>

                                {hoveredRow === row.id &&
                                  cell.column.id === "sequence" && (
                                    <Icon
                                      ml={2}
                                      display={"inline"}
                                      color={
                                        hasCopied
                                          ? "highlight.primary"
                                          : "gray.500"
                                      }
                                      boxSize={"14px"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopy();
                                      }}
                                      cursor={"pointer"}
                                      as={hasCopied ? FaCheck : BiSolidCopy}
                                    />
                                  )}
                              </Flex>
                            </Td>
                          );
                        }
                      })}
                    </Tr>
                  ))}
                  {isFetching && (
                    <Tr>
                      <Td colSpan={columns.length}>
                        <Loading />
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </CustomScrollBar>
      </TableContainer>

      {/* Rate limit modal */}
      <RateLimitModal
        isOpen={showRateModal}
        onClose={() => {
          window.localStorage.setItem(
            "initialRateLimitSeen",
            JSON.stringify(true)
          );
          setShowRateModal(false);
        }}
      />
      <NewSourceModal isOpen={showNewSourceModal} onClose={closeModal} />
      {layerName === "Private" && (
        <DeleteModal
          isOpen={showDeletePaperModal}
          onClose={handleCloseDeletePaperModal}
          onConfirm={handleConfirmDeletePaper}
          header={"Delete Literature"}
        >
          {deletingFile ? (
            <Loading message={"Deleting paper ..."} />
          ) : (
            <Box fontSize={"14px"} mt={4}>
              <Text>{"Are you sure you want to delete paper"}</Text>
              <Text fontWeight={"bold"}>{` ${paperTitle}?`}</Text>
            </Box>
          )}
        </DeleteModal>
      )}
    </Flex>
  );
};

export default AgnosticDataDisplay;
