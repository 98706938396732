import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { SessionsResponse } from "models/chat/MessageProps";

export const useChatBotAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  // Get the whole conversation for any session
  const fetchChatById = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, sessionId] = queryKey;
    const url = `${environment.BACKEND_API}/llm/get_or_delete_chat/${sessionId}`;
    return await axiosPrivate.get(url).then((res) => res.data);
  };


  // Get all session for the user
  const fetchSessions = async ({ pageParam = 1 }: { pageParam: number }): Promise<SessionsResponse> => {
    const url = `${environment.BACKEND_API}/api/get_sessions`;
    const response = await axiosPrivate.get(url, {
      params: {
        page: pageParam,
      }
    });
    return response.data;
  };

  return { fetchChatById, fetchSessions };
};
