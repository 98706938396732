import { useDispatch, useSelector } from "react-redux";
import {
  clearCredentials,
  selectCurrentAuthData,
} from "redux/features/auth/authSlice";
import { axiosClient } from "api/axios";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { TeamSocketContext } from "hooks/posts/usePostWS";
import { useQueryClient } from "@tanstack/react-query";

export default function useLogout() {
  // State
  const [loggingOut, setLoggingOut] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { refreshToken, user } = useSelector(selectCurrentAuthData);
  const { setTeamMessages, teamSocket } = useContext(TeamSocketContext);
  const isThirdPartyUser = user?.hasOwnProperty("identities");

  const logout = async () => {
    try {
      setLoggingOut(true);
      setTeamMessages([]);

      await axiosClient.post("/api/logout", {
        refreshToken,
        oauth2: isThirdPartyUser,
      });
      teamSocket.current?.close();

      queryClient.clear();
      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged out successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box color={"white"} p={3} bg={"red"} borderRadius={"6px"}>
            Failed to log out, try again
          </Box>
        ),
      });
    } finally {
      dispatch(clearCredentials());
      setLoggingOut(false);
    }
  };

  return { logout, loggingOut };
}
