import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import { Flex, Heading, Text, useMediaQuery } from "@chakra-ui/react";

import ActionButton from "components/home/ActionButton";
import ResponsivenessInfo from "components/home/ResponsivenessInfo";

export default function ActionCall() {
  // State
  const [showInfo, setShowInfo] = useState(false);

  // Navigation
  const navigate = useNavigate();

  // Redux
  const { user } = useSelector(selectCurrentAuthData);
  const isUser = !!(user && user?.id);

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Handler
  function handleClick() {
    if (isUser) {
      if (isMobileView) {
        setShowInfo && setShowInfo(true);
      } else {
        navigate("/chat");
      }
    } else {
      navigate("/login");
    }
  }

  return (
    <>
      <Flex
        direction={"column"}
        my={"80px"}
        align={"center"}
        textAlign={"center"}
        maxW={"1440px"}
        mx={"auto"}
        w={"95%"}
        gap={10}
      >
        {/* Heading */}
        <Flex direction={"column"} gap={4} align={"center"}>
          <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
            {"Get Started today".toUpperCase()}
          </Text>
          <Heading size={"lg"} fontFamily={"monospace"} w="fit-content">
            {"Instant Research Boost"}
          </Heading>
        </Flex>

        {/* Content */}
        <Text
          fontSize={{ base: "md", lg: "xl" }}
          maxW={{ base: "95%", md: "460px" }}
        >
          Don’t miss out - get started with Lakesai and never look back!
        </Text>

        {/* Button */}
        <ActionButton text={"Get Started"} onClick={handleClick} />
      </Flex>

      {/* Responsiveness Info modal content */}
      <ResponsivenessInfo
        isOpen={showInfo && isMobileView}
        onClose={() => setShowInfo(false)}
      />
    </>
  );
}
