import { Fragment } from "react";
import { Document, Font, Page, Text, View } from "@react-pdf/renderer";

import { ConversationProps } from "models/chat/MessageProps";
import MarkedReplyToExport from "./MarkedReplyToExport";
import { removeCitationsIDs, styles } from "./helpers";

interface ChatToExportProps {
  title: string;
  messages: ConversationProps[];
}

interface ChatHeadingProps {
  title: string;
}

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
    { src: "/fonts/Roboto-MediumItalic.ttf", fontStyle: "italic" },
    {
      src: "/fonts/Roboto-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

function ChatToExport({ title, messages }: ChatToExportProps) {
  return (
    <Document>
      <Page style={styles.body}>
        {/* document title */}
        <ChatHeading title={title} />

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {messages?.map((msg, idx) => {
            const question =
              msg?.messages?.at(0)?.human?.trim() ?? "cannot load the question";
            const reply = msg?.messages?.at(0)?.ai?.trim();
            const processedReply = removeCitationsIDs(reply ?? "");

            // display only valid bot replies
            const hasError = (msg?.ai ?? "")?.includes(
              "I'm currently experiencing technical difficulties"
            );

            if (hasError) return <></>;

            return (
              <Fragment key={idx}>
                {/* research question */}
                <Question content={question} />

                {/* bot reply */}
                <Text style={{ lineHeight: 2, textAlign: "justify" }}>
                  <MarkedReplyToExport reply={processedReply} />
                </Text>
              </Fragment>
            );
          })}
        </View>

        {/* pagination */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export default ChatToExport;

function ChatHeading({ title }: ChatHeadingProps) {
  return (
    <Text style={styles.title}>
      {(title as string) ?? "cannot load conversation title"}
    </Text>
  );
}

function Question({ content }: { content: string }) {
  return <Text style={styles.question}>{content}</Text>;
}
