import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { NotificationProps } from "models/notifications/NotificationProps";
import NotificationItem from "./NotificationItem";
import NotificationsLoadingIndicator from "./NotificationsLoadingIndicator";
import ShadowOverlay from "./ShadowOverlay";


export interface NotificationStyle {
    lightColor: string;
    darkColor: string;
    icon: React.ComponentType;
}

interface NotificationsContainerProps {
    notifications: NotificationProps[];
    getNotificationProps: (type: string) => NotificationStyle;
    formatDate: (date: Date) => string;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    onLoadMore: () => void;
}


const NotificationsContainer: React.FC<NotificationsContainerProps> = ({
    notifications,
    getNotificationProps,
    formatDate,
    hasNextPage,
    isFetchingNextPage,
    onLoadMore,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [showTopShadow, setShowTopShadow] = useState(false);
    const [showBottomShadow, setShowBottomShadow] = useState(true);

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            setShowTopShadow(scrollTop > 0);
            setShowBottomShadow(scrollTop + clientHeight < scrollHeight - 1);

            // Check if user has scrolled near the bottom
            const scrollThreshold = 100;
            const isNearBottom = scrollHeight - (scrollTop + clientHeight) < scrollThreshold;

            if (isNearBottom && hasNextPage && !isFetchingNextPage) {
                onLoadMore();
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            handleScroll();
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [hasNextPage, isFetchingNextPage]);

    return (
        <Box position="relative" borderRadius="xl" overflow="hidden">
            <Stack
                ref={containerRef}
                spacing={2}
                maxHeight="350px"
                overflowY="auto"
                position="relative"
                className="custom-scrollbar"
                sx={{
                    "&.custom-scrollbar::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&.custom-scrollbar::-webkit-scrollbar-track": {
                        background: "transparent",
                    },
                    "&.custom-scrollbar::-webkit-scrollbar-thumb": {
                        background: "#CBD5E0",
                        borderRadius: "4px",
                    },
                }}
            >
                {notifications.map((notification) => {
                    const { lightColor, darkColor, icon } = getNotificationProps(
                        notification.type
                    );
                    const mainColor = notification?.is_read ? lightColor : darkColor;

                    return (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            mainColor={mainColor}
                            icon={icon}
                            formatDate={formatDate}
                        />
                    );
                })}
                {isFetchingNextPage && <NotificationsLoadingIndicator />}
            </Stack>

            <ShadowOverlay
                overlayPosition="top"
                opacity={showTopShadow ? 1 : 0}
                transition="opacity 0.3s ease-in-out"
            />
            <ShadowOverlay
                overlayPosition="bottom"
                opacity={showBottomShadow ? 1 : 0}
                transition="opacity 0.3s ease-in-out"
            />
        </Box>
    );
};


export default NotificationsContainer