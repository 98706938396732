import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoIosFlash } from "react-icons/io";

interface UpgradePlanModalProps {
  isOpen: boolean;
  layer: string;
  onClose: () => void;
}

function UpgradePlanModal({ isOpen, onClose, layer }: UpgradePlanModalProps) {
  // Hooks
  const navigate = useNavigate();

  // Responsiveness
  const fromLeft = useBreakpointValue({ base: "5%", lg: "33%" });
  const maxW = useBreakpointValue({ base: "100%", lg: "550px", xl: "550px" });
  const width = useBreakpointValue({
    base: "100%",
    lg: "fit-content",
    xl: "760px",
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >

      <ModalOverlay bg="transparent" pointerEvents={"none"} />
      <ModalContent
        p={1}
        bottom="2%"
        w={width}
        h={"75px"}
        maxW={maxW}
        left={fromLeft}
        bg={"background"}
        position="absolute"
        borderRadius={"12px"}
        transform="translateX(-50%)"
        zIndex="10"
        pointerEvents="auto"
      >
        <ModalCloseButton
          position="absolute"
          top="50%"
          right="8px"
          transform="translateY(-50%)"
          onClick={onClose}
        />
        <ModalBody mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Flex direction="column" gap={2}>
              <Heading as="h4" size="sm" fontWeight={700}>
                Premium plan required
              </Heading>

              <Text fontFamily={"Poppins, sans-serif"} fontSize={"12px"}>
                Upgrade your plan to access <b>{layer}</b> layer
              </Text>
            </Flex>

            <Flex align="center" gap={1} alignItems="flex-end" mr={4}>
              <Button
                type={"submit"}
                borderRadius={"30px"}
                bg={"highlight.primary"}
                color={"background"}
                h={"fit-content"}
                w={"fit-content"}
                py={2}
                px={4}
                fontSize={"14px"}
                fontWeight={"500"}
                letterSpacing={".02rem"}
                leftIcon={<IoIosFlash />}
                _active={{ bg: "highlight.primary" }}
                _hover={{ bg: "highlight.primary" }}
                _focus={{ bg: "highlight.primary" }}
                _focusWithin={{ bg: "highlight.primary" }}
                onClick={() => navigate("/pricing")}
              >
                Upgrade
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UpgradePlanModal;
