import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  Input,
} from "@chakra-ui/react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { SavedFolderProps } from "models/bookmarks/SavedProps";

interface EditFolderModalProps {
  isOpen: boolean;
  onUpdateFolderTitle: (title: string, id: string) => void;
  onOpenRenameModal: (folder: SavedFolderProps) => void;
  selectedFolderId: string;
  onCloseRenameModal: () => void;
  existingTitle: string;
  updatingFolder: boolean;
}

export default function RenameFolderModal({
  isOpen,
  onUpdateFolderTitle,
  onOpenRenameModal,
  selectedFolderId,
  onCloseRenameModal,
  existingTitle,
  updatingFolder,
}: EditFolderModalProps) {
  // Hook
  const inputRef = useRef<HTMLInputElement>(null);

  // State
  const [title, setTitle] = useState(existingTitle);

  useEffect(() => {
    // Update the state whenever the existingTitle prop changes
    setTitle(existingTitle);
  }, [existingTitle]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseRenameModal}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        bg={"background"}
        borderRadius={"6px"}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            inputRef.current?.value &&
            onUpdateFolderTitle(inputRef.current?.value, selectedFolderId);
          }
        }}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={MdOutlineModeEditOutline}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            Rename folder
          </Text>
        </ModalHeader>

        <ModalBody>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outline"
            placeholder="Enter folder name.."
            _placeholder={{ fontSize: "14px", color: "gray.400" }}
            ref={inputRef}
            bg={"transparent"}
            _focus={{
              outline: "none",
              borderWidth: 1,
              boxShadow: "md",
              overflow: "auto",
            }}
            _focusVisible={{ outline: "none" }}
          />
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            pointerEvents={updatingFolder ? "none" : "auto"}
            borderRadius={"100px"}
            onClick={onCloseRenameModal}
          >
            Cancel
          </Button>
          <Button
            minW={"140px"}
            bg={"highlight.primary"}
            color={"gray.50"}
            border={"none"}
            _hover={{ opacity: 0.9 }}
            isLoading={updatingFolder ?? false}
            pointerEvents={updatingFolder ? "none" : "auto"}
            loadingText={"Updating..."}
            borderRadius={"100px"}
            onClick={() =>
              title && onUpdateFolderTitle(title, selectedFolderId)
            }
          >
            Rename
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
