import { useEffect, useState } from "react";
import { Box, Flex, Icon, Tooltip, useTheme } from "@chakra-ui/react";
import { FaMicrophone } from "react-icons/fa6";
import { BsX } from "react-icons/bs";
import styled from "styled-components";
import { keyframes } from "@emotion/react";
import { hexToRgba } from "utils/helpers";

interface AudioRecordingProps {
  setUploadedAudio: (audio: Blob | null) => void;
  setRecordedAudio: (recordedAudio: Blob | null) => void;
  recording: boolean;
  setRecording: (val: boolean) => void;
  user: any;
  recordedAudio: Blob | null;
}

const pulseBorder = keyframes`
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
`;

const StyledAudio = styled.audio<{ bgColor: string }>`
  &::-webkit-media-controls-panel,
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-volume-slider {
    background-color: ${(props) => props.bgColor} !important;
  }
`;

export default function AudioRecording({
  setUploadedAudio,
  setRecordedAudio,
  recording,
  setRecording,
  user,
  recordedAudio,
}: AudioRecordingProps) {
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const bgColor = colorMode === "light" ? "background" : "#0B2E3D";

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  const SILENCE_THRESHOLD_RMS = 30; // Adjust threshold as necessary
  const SILENCE_DURATION = 4000; // Silence duration threshold (in milliseconds)
  const DETECTION_INTERVAL = 100; // Check every 100ms for smoother detection

  // Declare silenceIntervalId in the component state
  const [silenceIntervalId, setSilenceIntervalId] = useState<NodeJS.Timeout | null>(null);

  const handleStartRecording = async () => {
    if (recording) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.smoothingTimeConstant = 0.85; // Smooth out audio data

      const audioStreamSource = audioContext.createMediaStreamSource(stream);
      audioStreamSource.connect(analyser);

      const recorder = new MediaRecorder(stream);
      let recordedChunks: BlobPart[] = [];
      let silenceStartTime: number | null = null;

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) recordedChunks.push(e.data);
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(recordedChunks, { type: "audio/wav" });
        const audioFile = new File([audioBlob], `audio_${Date.now()}.wav`, {
          type: "audio/wav",
        });
        setUploadedAudio(audioFile);
        setRecordedAudio(audioFile);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);

      // Continuous silence check every 100ms
      const intervalId = setInterval(() => {
        const bufferLength = analyser.frequencyBinCount;
        const frequencyData = new Uint8Array(bufferLength);
        analyser.getByteFrequencyData(frequencyData);

        // Calculate RMS amplitude of the frequency data
        const rms = Math.sqrt(frequencyData.reduce((sum, value) => sum + value * value, 0) / frequencyData.length);

        // If RMS is below the threshold, consider it silent
        const isSilent = rms < SILENCE_THRESHOLD_RMS;
        // Silence detection logic
        if (isSilent) {
          if (!silenceStartTime) {
            silenceStartTime = Date.now();
          } else if (Date.now() - silenceStartTime > SILENCE_DURATION) {
            clearInterval(intervalId); // Clear the interval when silence is detected
            recorder.stop()
            setRecording(false);
          }
        } else {
          // Reset silence start time if sound is detected
          silenceStartTime = null;
        }

      }, DETECTION_INTERVAL);

      // Store the intervalId in state
      setSilenceIntervalId(intervalId);

    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }

    // Clear any remaining interval when recording stops
    if (silenceIntervalId) {
      clearInterval(silenceIntervalId);
      setSilenceIntervalId(null); // Reset the interval ID
    }
  };

  useEffect(() => {
    return () => {
      if (silenceIntervalId) {
        clearInterval(silenceIntervalId);
      }
      mediaRecorder?.stop();
    };
  }, [silenceIntervalId, mediaRecorder]);

  const renderTooltipLabel = () => {
    if (!["teams", "premium", "basic"].includes(user.user_type)) {
      return "Upgrade to Enterprise, Basic or Premium plan";
    }
    if (recordedAudio) return "Remove the attached audio first to record a new one";
    return recording ? "Recording... click to stop recording" : "Record audio";
  };

  return (
    <>
      {recordedAudio && !recording && (
        <Flex p={0} height="20px" mt="2px" ml="2px" zIndex={1000}>
          <Box>
            <StyledAudio controls bgColor={bgColor}>
              <source
                src={URL.createObjectURL(recordedAudio)}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </StyledAudio>
          </Box>
          <Icon
            as={BsX}
            boxSize={5}
            cursor="pointer"
            color="gray.400"
            bg="background"
            borderRadius="10"
            borderWidth={1}
            onClick={() => setRecordedAudio(null)}
          />
        </Flex>
      )}

      <Tooltip
        label={renderTooltipLabel()}
        placement="bottom"
        bg="gray.900"
        color="gray.100"
        hasArrow
        py={2}
        px={3}
        borderRadius="6px"
      >
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          top={recording ? "15%" : "35%"}
          right={recording ? 2 : 16}
          width={recording ? "40px" : "fit-content"}
          height={recording ? "40px" : "fit-content"}
          borderRadius="50%"
          border={
            recording
              ? `2px solid ${hexToRgba(colors.red[500], 0.9)}`
              : "transparent"
          }
          animation={recording ? `${pulseBorder} 1s infinite` : "none"}
          bg={recording ? hexToRgba(colors.red[500], 0.2) : "transparent"}
        >
          <Icon
            as={FaMicrophone}
            cursor="pointer"
            boxSize={4}
            color={recording ? "red.500" : "primary.300"}
            onClick={recording ? stopRecording : handleStartRecording}
            _hover={{ color: recording ? "red.500" : "highlight.primary" }}
          />
        </Box>
      </Tooltip>
    </>
  );
}
