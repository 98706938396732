import { useNavigate } from "react-router-dom";
import useAllowAccess from "hooks/auth/useAllowAccess";
import { PERMISSIONS, TYPES } from "utils/premissions";

import { Button, Flex, Image, Text } from "@chakra-ui/react";
import LinkToView from "components/buttons/LinkToView";

import { BiHomeAlt } from "react-icons/bi";
import notFoundImg from "assets/notFound.svg";
import { BsChatRightQuote, BsDatabase, BsPeople } from "react-icons/bs";

export default function PageNotFound() {
  const { userIsAnAllowedTypes, userHasRequiredPermissions } = useAllowAccess();

  // Hook
  const navigate = useNavigate();

  // Handler
  function handleGoHome() {
    navigate("/");
  }

  return (
    <Flex
      h={"100vh"}
      w={"full"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"40px"}
      bg={"background"}
    >
      <Image
        src={notFoundImg}
        alt={"404"}
        w={{ base: "70%", sm: "200px" }}
        maxW={{ base: "200px", sm: "auto" }}
        objectFit={"cover"}
      />
      <Text
        fontSize={"14px"}
        color={"gray.500"}
        textAlign={"center"}
        maxW={"80%"}
      >
        It seems we could not find the page you were looking for
      </Text>

      <Flex direction={"column"} align={"center"} justify={"center"} gap={3}>
        <Text
          color={"gray.500"}
          opacity={0.85}
          fontSize={"14px"}
          fontWeight={"500"}
        >
          Suggested pages:{" "}
        </Text>
        <Flex align={"center"} justify={"center"} gap={2} flexWrap={"wrap"}>
          <LinkToView name="Assistant" icon={BsChatRightQuote} link="/chat" />
          <LinkToView name="Data Library" icon={BsDatabase} link="/data" />
          {(userIsAnAllowedTypes([TYPES.TEAMS]) ||
            userHasRequiredPermissions([PERMISSIONS.TEAMS_INVITEE])) && (
            <LinkToView name="Team" icon={BsPeople} link="/team" />
          )}
        </Flex>
      </Flex>

      <Flex
        direction={"column"}
        textAlign={"center"}
        my={2}
        gap={2}
        align={"center"}
      >
        <Text
          fontSize={{ base: "12px", sm: "14px" }}
          color={"gray.500"}
          whiteSpace={"nowrap"}
        >
          or
        </Text>
        <Button
          bg={"gray.700"}
          color={"gray.50"}
          border={"none"}
          _hover={{ opacity: 0.9 }}
          borderRadius={"24px"}
          fontSize={"14px"}
          type="submit"
          w={"fit-content"}
          px={"20px"}
          py={2}
          leftIcon={<BiHomeAlt />}
          onClick={handleGoHome}
        >
          Go to Homepage
        </Button>
      </Flex>
    </Flex>
  );
}
