import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationsAPI } from "./useNotificationsAPI";
import { useToast } from "@chakra-ui/react";
import { errorHandler } from "utils/helpers";
import { NotificationsResponse } from "models/notifications/NotificationProps";

export const useMarkNotificationAsRead = () => {
    const { markNotificationsAsRead } = useNotificationsAPI()
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async () => {
            queryClient.setQueryData<InfiniteData<NotificationsResponse>>(
                ["notifications"],
                (oldData) => {
                    if (!oldData) return oldData;
                    return {
                        ...oldData,
                        pages: oldData.pages.map(page => ({
                            ...page,
                            unread_count: 0,
                            notifications: page.notifications.map(n => {
                                if (!n.is_read) {
                                    return {
                                        ...n,
                                        is_read: true
                                    }
                                }
                                return n
                            })
                        }))
                    };
                }
            );

            const res = await markNotificationsAsRead();
            return res;
        },
        onSuccess: async () => {
            // .. 
        },
        onError: (error) => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
            toast({
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
        },
    });
};