import { Box, Flex, Text, Icon, useTheme } from "@chakra-ui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { NotificationProps } from "models/notifications/NotificationProps";
import { useDeleteNotificationMutation } from "api/notifications/useDeleteNotificationMutation";
import { hexToRgba } from "utils/helpers";
import { truncateString } from "../ui/helpers";

interface NotificationItemProps {
    notification: NotificationProps;
    mainColor: string;
    icon: React.ComponentType;
    formatDate: (date: Date) => string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    mainColor,
    icon,
    formatDate,
}) => {
    // Theme
    const { colors } = useTheme();

    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";
    const itemBg = isLight
        ? "background"
        : hexToRgba(colors.secondary[100], 0.15);

    const { mutate: deleteNotificationMutation } = useDeleteNotificationMutation();

    const title = truncateString(
        notification.title || notification.type.toUpperCase(),
        18
    );

    const handleNotificationRemove = (id: string) => deleteNotificationMutation(id);

    return (
        <Box
            px={1}
            py={1.5}
            bg={notification?.is_read ? itemBg : "lightBackground"}
            borderRadius="md"
            borderLeft="4px solid"
            borderColor={mainColor}
            boxShadow="0 1px 3px rgba(0,0,0,0.1)"
            transition="all 0.2s ease"
            _hover={{
                transform: "translateY(-1px)",
                boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                bg: notification?.is_read
                    ? hexToRgba(colors.secondary[100], 0.15)
                    : "lightBackground",
            }}
        >
            <Flex justify="space-between" align="center" mb={2}>
                <Flex align="center" gap={1}>
                    <Icon as={icon} color={mainColor} boxSize={4} />
                    <Text
                        fontSize="12px"
                        color={mainColor}
                        fontWeight={!notification?.is_read ? "semibold" : "normal"}
                        letterSpacing="tight"
                        fontStyle="italic"
                        cursor="default"
                    >
                        {title}
                    </Text>
                </Flex>
                <Flex align="center" gap={1}>
                    <Text
                        fontSize="10px"
                        fontWeight={!notification?.is_read ? "semibold" : "normal"}
                        color={!notification?.is_read ? "gray.600" : "gray.500"}
                        fontStyle="italic"
                        cursor="default"
                    >
                        {formatDate(new Date(notification.created_at))}
                    </Text>
                    <Icon
                        as={IoCloseCircleOutline}
                        boxSize={4}
                        color="gray.400"
                        cursor="pointer"
                        title="remove notification"
                        _hover={{ color: "red.400" }}
                        transition="all 0.3s ease-in-out"
                        onClick={() => handleNotificationRemove(notification.id)}
                    />
                </Flex>
            </Flex>
            <Text
                fontSize="10"
                fontWeight={!notification?.is_read ? "semibold" : "normal"}
                color={!notification?.is_read ? "gray.700" : "gray.500"}
                cursor={"default"}
                wordBreak={'break-all'}
                pl={2}
            >
                {notification.message}
            </Text>
        </Box>
    );
};

export default NotificationItem