import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  selectCurrentPostsData,
  setTeamData,
} from "redux/features/posts/postsSlice";

import { Box, Flex, HStack, Text, useTheme } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { useTeamsAPI } from "api/useTeamsAPI";
import { errorHandler, hexToRgba } from "utils/helpers";
import MainPanelError from "components/ui/MainPanelError";
import useFetchSavedElements from "hooks/bookmarks/useFetchSavedElements";
import { useRefreshContext } from "hooks/team/RefreshContext";

const titles = ["Members", "Folders", "Literature", "Posts", "Activity"];

export default function Team() {
  // States
  const [errMessage, setErrMessage] = useState<any>();
  const { isRefreshing } = useRefreshContext();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getTeam } = useTeamsAPI();
  const { user } = useSelector(selectCurrentAuthData);
  let { unReadMessages } = useSelector(selectCurrentPostsData);

  const hasResponse = (
    error: unknown
  ): error is { response: { data: { message: string } } } => {
    return (error as any)?.response?.data?.message !== undefined;
  };

  // Theme
  const { colors } = useTheme();

  useFetchSavedElements();

  const { data, isLoading, error } = useQuery({
    queryKey: ["team"],
    queryFn: getTeam,
    retry: 1,
  });

  useEffect(() => {
    if (data) dispatch(setTeamData(data));
    if (error) {
      setErrMessage(
        hasResponse(error)
          ? error.response.data.message
          : "An unexpected error occurred"
      );
    }
  }, [data, dispatch, error]);

  return (
    <Flex
      height="100%"
      width={["90%", "80%"]}
      maxW="98%"
      direction="column"
      mx="auto"
      py={4}
      gap="16px"
      overflow={"hidden"}
    >
      {/* Tabs */}
      <Flex
        w={"100%"}
        justify={"center"}
        align={"center"}
        p={3}
        borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
        borderBottomWidth={1}
      >
        <Flex w={"fit-content"} h={"fit-content"}>
          {location.pathname.includes("accept-invite") === false && (
            <>
              {titles.map((title, index) => {
                // FIXME: Skip rendering certain titles based on conditions,
                // but return null instead of using return;
                if (user?.id !== data?.owner && title === "Activity")
                  return null;
                if (errMessage && title === "Posts") return null;
                if (!data && title === "Literature") return null;

                const isSelected = location.pathname.includes(
                  title.toLowerCase()
                );
                const isRoot = location.pathname === "/team" && index === 0;
                const isActive = isSelected || isRoot;
                return (
                  <Box
                    key={index}
                    px={5}
                    py={2}
                    cursor="pointer"
                    w={"fit-content"}
                    textAlign={"center"}
                    borderRadius={"99px"}
                    bg={
                      isActive
                        ? hexToRgba(colors.blue[300], 0.15)
                        : "transparent"
                    }
                    color={isActive ? "blue.500" : "gray.500"}
                    transition={"all .3s ease"}
                    onClick={() =>
                      navigate(
                        `/team${
                          title === "Members" ? "" : "/" + title.toLowerCase()
                        }`
                      )
                    }
                  >
                    <HStack justifyContent={"space-between"}>
                      <Text fontSize="md">{title}</Text>
                      {title === "Posts" && unReadMessages > 0 && (
                        <Box
                          bg={"red.500"}
                          borderRadius={"full"}
                          width={2.5}
                          height={2.5}
                        ></Box>
                      )}
                    </HStack>
                  </Box>
                );
              })}
            </>
          )}
        </Flex>
      </Flex>

      {/* Content */}
      <Box pt={2} height={"calc(100% - 58px)"} width="100%">
        {isLoading || isRefreshing ? (
          <Flex
            h={"100%"}
            w={"100%"}
            align={"center"}
            justify={"center"}
            color={"gray.500"}
            direction={"column"}
          >
            <Loading message="Loading team data ..." />
          </Flex>
        ) : error && errMessage !== "You do not have a team" ? (
          <MainPanelError errorMessage={errorHandler(error).message} />
        ) : (
          <Outlet />
        )}
      </Box>
    </Flex>
  );
}
