import {
  Flex,
  Text,
  Heading,
  useTheme,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const bgPosition = keyframes`
  from { background-position: 0% center; }
  to { background-position: -200% center; }`;

export default function TargetAudience() {
  // Theme
  const { colors } = useTheme();

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const bgPositionAnimation = prefersReducedMotion
    ? undefined
    : `${bgPosition} 4s linear infinite`;

  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      maxW={"1000px"}
      mx={"auto"}
      w={"95%"}
      align={{ base: "center", xl: "flex-end" }}
      gap={{ base: 10, xl: 4 }}
    >
      {/* values heading */}
      <Flex
        direction={"column"}
        w={{ base: "fit-content", xl: "40%" }}
        gap={{ base: 10, xl: 1 }}
      >
        {/* Heading */}
        <Flex
          direction={"column"}
          textAlign={{ base: "center", xl: "left" }}
          gap={4}
        >
          <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
            {"Our Values".toUpperCase()}
          </Text>
          <Heading size={"lg"} fontFamily={"monospace"}>
            {"We are known for:"}
          </Heading>
        </Flex>

        {/* Text */}
        <Text fontSize={{ base: "sm", lg: "md" }} maxW="660px">
          Our values drive us to continually improve and grow, with innovation
          at the heart of our approach. Integrity guides our actions, ensuring
          we stay true to our principles. We embrace empathy to understand and
          support those we serve, creating a positive impact in all we do.
        </Text>
      </Flex>

      {/* values content */}
      <Flex
        gap={{ base: 4, xl: 2 }}
        w={{ base: "100%", lg: "80%", xl: "60%" }}
        maxW={{ base: "660px", xl: "100%" }}
        flexWrap={{ base: "wrap", xl: "nowrap" }}
      >
        {/* value 1 */}
        <Flex
          direction={"column"}
          w={{ base: "100%", xl: "28%", "2xl": "27%" }}
          justify={{ base: "flex-start", xl: "flex-end" }}
          gap={2}
        >
          <Heading
            size={"xs"}
            fontFamily={"Poppins, sans-serif"}
            fontWeight={"700"}
            background={`linear-gradient(to right, #00C1B4, ${colors.purple[500]},  #1A86CE, #00C1B4)`}
            animation={bgPositionAnimation}
            backgroundSize={"200%"}
            whiteSpace={"nowrap"}
            bgClip={"text"}
            w={"fit-content"}
          >
            Innovation
          </Heading>
          <Text fontSize={{ base: "sm", lg: "md" }}>
            We drive innovation by creating bold, forward-thinking solutions
            that push boundaries and deliver real impact.
          </Text>
        </Flex>

        {/* value 2 */}
        <Flex
          direction={"column"}
          w={{ base: "100%", xl: "31%", "2xl": "31%" }}
          justify={{ base: "flex-start", xl: "flex-end" }}
          gap={2}
        >
          <Heading
            size={"xs"}
            fontFamily={"Poppins, sans-serif"}
            fontWeight={"700"}
            background={`linear-gradient(to right, #00C1B4, ${colors.purple[500]},  #1A86CE, #00C1B4)`}
            animation={bgPositionAnimation}
            backgroundSize={"200%"}
            whiteSpace={"nowrap"}
            bgClip={"text"}
            w={"fit-content"}
          >
            Integrity
          </Heading>
          <Text fontSize={{ base: "sm", lg: "md" }}>
            We prioritize integrity and trust, creating groundbreaking solutions
            that are ethically sound and built on data security.
          </Text>
        </Flex>

        {/* value 3 */}
        <Flex
          direction={"column"}
          w={{ base: "100%", xl: "41%", "2xl": "42%" }}
          justify={{ base: "flex-start", xl: "flex-end" }}
          gap={2}
        >
          <Heading
            size={"xs"}
            fontFamily={"Poppins, sans-serif"}
            fontWeight={"700"}
            background={`linear-gradient(to right, #00C1B4, ${colors.purple[500]},  #1A86CE, #00C1B4)`}
            animation={bgPositionAnimation}
            backgroundSize={"200%"}
            whiteSpace={"nowrap"}
            bgClip={"text"}
            w={"fit-content"}
          >
            Empathy
          </Heading>
          <Text fontSize={{ base: "sm", lg: "md" }}>
            Empathetic AI is our DNA! We are passionate about bridging
            healthcare gaps, empowering teams, and ultimately transforming
            patient stories through innovative solutions.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
