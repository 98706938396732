import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

import { environment } from "environments";
import {
  selectCurrentSavedToolsData,
  setFail,
  setLoading,
  setSuccess,
} from "redux/features/tools/toolsSlice";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

const useFetchSavedTools = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const { loading, error, savedTools } = useSelector(
    selectCurrentSavedToolsData
  );

  const { user } = useSelector(selectCurrentAuthData);

  useEffect(() => {
    const fetchSavedTools = async () => {
      dispatch(setLoading());

      try {
        const ALLOWED_TIERS = ["premium", "teams"];
        if (user?.user_type && ALLOWED_TIERS.includes(user?.user_type)) {
          const response = await axiosPrivate.get(
            `${environment.BACKEND_API}/api/user_tools`
          );
          dispatch(setSuccess([...response?.data]));
        }
      } catch (error) {
        dispatch(setFail("An error occurred"));
        // TODO: Instead of toast, show modalManager, to indicate that they need to upgrade to premium or enterprise
        toast({
          title: "An error occurred",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      }
    };

    fetchSavedTools();
  }, [axiosPrivate, dispatch, toast, user]);

  return { loading, error, savedTools };
};

export default useFetchSavedTools;
