import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Flex, Text, Icon, Heading } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useProteinsAPI } from "api/useProteinsAPI";
import Loading from "components/ui/Loading";
import ProteinDetail from "./ProteinDetail";
import { CoreContext } from "views/library/core";
import { Protein } from "models/proteins";
import MainPanelError from "components/ui/MainPanelError";

import { errorHandler } from "utils/helpers";
import { BsFileEarmarkX } from "react-icons/bs";

function ProteinView() {
  // Hooks
  const { sourceItemId: id } = useParams();

  const { setSourceItemName } = useContext(CoreContext);
  const { fetchProteinById } = useProteinsAPI();

  // API
  const { isLoading, error, data } = useQuery<Protein>({
    queryKey: ["protein", id],
    queryFn: fetchProteinById,
    staleTime: Infinity, // always fresh since it rarely changes
    gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
  });

  useEffect(() => {
    if (data) {
      setSourceItemName(data?.protein_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    const errorStatus = (error as AxiosError)?.status;

    return (
      <Flex w={"100%"} h={"100%"} my={10}>
        {errorStatus === 404 ? (
          // throws this error when Protein does not exist
          <Flex
            h={"100%"}
            w={"100%"}
            align={"center"}
            justify={"center"}
            color={"gray.500"}
            direction={"column"}
            gap={6}
          >
            <Icon as={BsFileEarmarkX} boxSize={"40px"} />
            <Text fontSize={"14px"} lineHeight={"1.28"} fontWeight={"500"}>
              No Protein found
            </Text>
          </Flex>
        ) : (
          // another error
          <MainPanelError errorMessage={errorHandler(error)?.message} />
        )}
      </Flex>
    );
  }

  if (isLoading) {
    return (
      <Flex
        w={"100%"}
        h={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
        my={10}
      >
        <Loading message={`Loading protein data...`} />
      </Flex>
    );
  }

  return (
    <Flex direction={"column"} gap={8}>
      <Flex gap={4} direction={"column"}>
        <Heading
          as={"h4"}
          fontSize={[null, null, null, "16px", "18px", "18px"]}
          color={"secondary.600"}
        >
          Protein Details
        </Heading>

        <Flex direction="row" gap={5} mb={5} align={"center"}>
          <Flex direction={"column"} gap={2}>
            <ProteinDetail header="Name" content={data?.protein_name ?? ""} />
            <ProteinDetail header="Gene" content={data?.gene_name ?? ""} />
            <ProteinDetail
              header="Sequence"
              content={data?.protein_sequence ?? ""}
              isSequence
            />
            <ProteinDetail header="Organism" content={data?.organism ?? ""} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ProteinView;
