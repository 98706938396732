import { ColumnOrderState } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import {
  ColumnFiltersProp,
  AgnosticDataDisplayProps,
} from "./AgnosticDataDisplay";
import { FiltersState } from "views/library/core";

export const handleDragStart = (
  e: React.DragEvent<HTMLTableCellElement>,
  headerId: string
) => {
  e.dataTransfer.setData("text/plain", headerId);
  e.currentTarget.style.backgroundColor = "#dce0e5";
};

export const handleDrop = (
  e: React.DragEvent<HTMLTableCellElement>,
  targetHeaderId: string,
  columnOrder: ColumnOrderState,
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>
) => {
  e.preventDefault();
  const draggedHeaderId = e.dataTransfer.getData("text/plain");
  const newColumnOrder = [...columnOrder];
  const draggedIndex = newColumnOrder.indexOf(draggedHeaderId);
  const targetIndex = newColumnOrder.indexOf(targetHeaderId);

  newColumnOrder.splice(draggedIndex, 1);
  newColumnOrder.splice(targetIndex, 0, draggedHeaderId);
  setColumnOrder(newColumnOrder);
  e.currentTarget.style.border = "";
};

export const handleDragEnd = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.backgroundColor = "";
};

export const handleDragOver = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.preventDefault();
  e.currentTarget.style.border = "2px dashed #cbd5e0";
};

export const handleDragLeave = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.border = "";
};

export const handleFilterClear = (
  headerId: string,
  setColumnsFilters: React.Dispatch<React.SetStateAction<FiltersState>>,
  setFilters: React.Dispatch<React.SetStateAction<ColumnFiltersProp>>
) => {
  setColumnsFilters((prev: any) => {
    const newFilters = {
      ...prev,
      showFilter: prev.showFilter === headerId ? null : prev.showFilter,
    };
    delete newFilters.filters[headerId];
    return newFilters;
  });
  setFilters((prev) => {
    const newFilters = { ...prev };
    delete newFilters[headerId];
    return newFilters;
  });
};

export const handleRowClick = (
  row: any,
  source: AgnosticDataDisplayProps["source"],
  navigate: ReturnType<typeof useNavigate>,
  toast: (options: any) => void,
  deleting: boolean
) => {
  if (source.key === "PROPRIETARY") {
    if (!row.is_processing) {
      navigate(row.id);
    } else {
      if (!deleting) {
        toast({
          description:
            "Please wait until the processing is complete to view the content",
          status: "warning",
          position: "top-right",
        });
      }
    }
  } else {
    if (source.key === "MOLECULES") navigate(row.compound_id);
    if (source.key === "ASSAYS") navigate(row.assay_id);
    if (source.key === "LITERATURE") navigate(row.id);
    if (source.key === "PROTEINS") navigate(row.id);
    if (source.key === "5'UTRS") navigate(row.id);
    if (source.key === "3'UTRS") navigate(row.id);

  }
};

export const handleFilterInputKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  headerId: string,
  clearSearch: () => void,
  setFilters: React.Dispatch<React.SetStateAction<ColumnFiltersProp>>
) => {
  if (e.key === "Enter") {
    clearSearch();
    const value = (e.target as HTMLInputElement).value;
    setFilters((prev) => ({ ...prev, [headerId]: value }));
  }
};

export const handleFilterIconClick = (
  headerId: any,
  setColumnsFilters: React.Dispatch<React.SetStateAction<FiltersState>>,
  inputRefs: React.MutableRefObject<Map<string, HTMLInputElement | null>>
) => {
  setColumnsFilters((prev) => ({
    ...prev,
    showFilter: prev.showFilter === headerId ? null : headerId,
  }));

  const inputRef = inputRefs.current.get(headerId);
  if (inputRef) {
    inputRef.focus();
  }
};

export const handleFilterInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  headerId: string,
  setColumnsFilters: React.Dispatch<React.SetStateAction<FiltersState>>
) =>
  setColumnsFilters((prev) => ({
    ...prev,
    filters: { ...prev.filters, [headerId]: e.target.value },
  }));
