import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SimpleGrid } from "@chakra-ui/react";

import { CoreContext } from "views/library/core";
import LayerCard from "components/library/core/layers/LayerCard";
import { ToolProps } from "models/tools/ToolsProps";
import { HIGHLIGHT_LAYERS } from "../helpers";
import UpgradePlanModal from "components/ui/UpgradePlanModal";

export interface LayersProps {
  id: string;
  name: string;
  description: string;
  icon: string;
  tools?: ToolProps[];
  created_at?: Date;
  date_modified?: Date;
  placeholder?: boolean;
}

export default function CoreLayersList() {
  // Hooks
  const navigate = useNavigate();

  // State
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState("mRNALake");

  // Handler
  function openLayer(path: string, placeholder: boolean | undefined) {
    if (placeholder) {
      setShowUpgradeModal(true);
      setSelectedLayer(path);
      return;
    }
    navigate(`/data/core/${path}`);
  }

  const { layers } = useContext(CoreContext);

  const totalLayers = 3;

  const updatedLayers = Array.from({ length: totalLayers }, (_, i) => {
    const existingLayer = layers[i];
    return existingLayer
      ? existingLayer
      : { ...HIGHLIGHT_LAYERS[i], placeholder: true };
  });

  if (!updatedLayers[2]?.name) {
    updatedLayers.pop();
  }

  return (
    <>
      <SimpleGrid
        templateColumns={"repeat(auto-fit, 270px)"}
        gap={"24px"}
        p={1}
      >
        {updatedLayers?.map((source: LayersProps, index: number) => (
          <LayerCard
            key={index}
            source={source}
            onClick={() => openLayer(source.name, source?.placeholder)}
          />
        ))}
      </SimpleGrid>

      <UpgradePlanModal
        isOpen={showUpgradeModal}
        layer={selectedLayer}
        onClose={() => {
          setShowUpgradeModal(false);
        }}
      />
    </>
  );
}
