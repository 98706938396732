import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";

import { deleteSession, update_session } from "services/chatbot.service";
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { categorizeSessions } from "views/chatbot/helpers";
import { useChatBotAPI } from "api/useChatBotAPI";

import { SessionsResponse } from "models/chat/MessageProps";

import ChatDate from "components/chat/history/ChatDate";
import ChatItem from "components/chat/history/ChatItem";
import DeleteModal from "components/ui/DeleteModal";
import NoChats from "components/chat/history/NoChats";
import RenameModal from "components/chat/history/RenameModal";
import SessionsSkeleton from "components/chat/history/SessionsSkeleton";

import Loading from "components/ui/Loading";
import ChatHistoryError from "./ChatHistoryError";
import BackgroundShadowEffect from "components/ui/BackgroundShadowEffect";
import useAllowAccess from "hooks/auth/useAllowAccess";
import { PERMISSIONS, TYPES } from "utils/premissions";

import { CustomThickScrollBar } from "components/ui/CustomScrollBar";
import SessionsLoadingIndicator from "components/chat/history/SessionsLoadingIndicator";
import { errorHandler } from "utils/helpers";
import ExportModal from "components/chat/history/ExportModal";

export default function ChatHistory() {
  // hooks
  const { userIsAnAllowedTypes, userHasRequiredPermissions } = useAllowAccess();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  // Session id
  const { id } = useParams();

  // States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState("");
  const [selectedSession, setSelectedSession] = useState<string | null>(null);

  // Refs
  const scrollContainerRef = useRef<any>(null);

  // Handlers
  async function handleDeleteSession(id: string) {
    setSelectedSession(id);
    setShowDeleteModal(true);
  }

  function handleExportChat(id: string) {
    setSelectedSession(id);
    setShowExportModal(true);
  }

  const handleRenameChatTitle = (id: string, title: string) => {
    setSelectedSession(id);
    setShowRenameModal(true);
    setSelectedChatTitle(title);
  };

  // API
  const { fetchSessions } = useChatBotAPI();

  // Fetch all sessions
  const {
    data: sessions,
    isLoading: loadingSessions,
    error: sessionsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<
    SessionsResponse,
    Error,
    InfiniteData<SessionsResponse>,
    [string],
    number
  >({
    queryKey: ["chatbot-sessions"],
    queryFn: ({ pageParam }) => fetchSessions({ pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage.has_next ? lastPage.current_page + 1 : undefined,
    initialPageParam: 1,
    retry: 1,
    staleTime: Infinity,
  });

  // Infinite Scroll Logic
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollBar = event.currentTarget;
    const { scrollHeight, scrollTop, clientHeight } = scrollBar;

    // Your infinite scroll logic
    let leftHeight = scrollHeight - scrollTop - clientHeight < 150;
    if (leftHeight && hasNextPage && !isFetchingNextPage) fetchNextPage();
  };

  async function handleConfirmDeleteSession() {
    setDeleting(true);

    try {
      if (!selectedSession) return;
      await deleteSession(selectedSession);
      selectedSession === id && navigate("/chat");

      // Update cache to remove deleted session
      queryClient.setQueryData(
        ["chatbot-sessions"],
        (oldData: InfiniteData<SessionsResponse> | undefined) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              sessions: page.sessions.filter(
                (session) => session.id !== selectedSession
              ),
            })),
          };
        }
      );
      setDeleting(false);
    } catch (error) {
      console.error(error, "error while deleting a session");
    }
    setShowDeleteModal(false);
    setSelectedSession(null);
  }

  async function handleUpdateChatTitle(title: string) {
    setShowRenameModal(true);
    setUpdating(true);

    if (!selectedSession) return;
    try {
      const payload = { title };

      await update_session(selectedSession, payload);

      // Update cache to rename session
      queryClient.setQueryData(
        ["chatbot-sessions"],
        (oldData: InfiniteData<SessionsResponse> | undefined) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              sessions: page.sessions.map((session) =>
                session.id === selectedSession ? { ...session, title } : session
              ),
            })),
          };
        }
      );

      setUpdating(false);
    } catch (error) {
      console.error(error, "error while updating chat title");
    }
    setShowRenameModal(false);
    setSelectedSession(null);
  }

  // Flatten sessions for rendering
  const flattenedSessions =
    sessions?.pages.flatMap((page) => page.sessions) || [];

  // user with fresh start or deleted all previous chats
  const hasNoChats = flattenedSessions?.length === 0;

  // true if user is a team member
  const isTeamMember =
    userIsAnAllowedTypes([TYPES.TEAMS]) ||
    userHasRequiredPermissions([PERMISSIONS.TEAMS_INVITEE]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  // NOTE: change these values depending on nbr of nav items
  // - 2 nav items: Library, Assistant: {lg: 257, xl: 274, 2xl: 286}
  // - 3 nav items: Team, Library, Assistant: {lg: 302, xl: 320, 2xl: 336}
  const chatHistoryHeight = useBreakpointValue({
    lg: `calc(100vh - ${isTeamMember ? 302 : 257}px)`, // (2.py) + (2.py) + logo + navItems* + avatar + gaps + mb
    xl: `calc(100vh - ${isTeamMember ? 320 : 274}px)`,
    "2xl": `calc(100vh - ${isTeamMember ? 336 : 286}px)`,
  });

  const listStyle = {
    height: chatHistoryHeight,
    width: "100%",
    padding: "0 6px 2px 0",
  };

  // Error handling
  if (loadingSessions === false && sessionsError) {
    toast({
      description: errorHandler(sessionsError).message,
      status: "error",
    });

    return <ChatHistoryError />;
  }

  // TODO: type annotations

  return (
    <>
      <Flex
        direction={"column"}
        w={"100%"}
        h={"100%"}
        gap={[null, null, null, 4, 5, 6]}
      >
        {/* sessions list */}
        <Flex position={"relative"} h={"100%"} w={"100%"}>
          <CustomThickScrollBar
            ref={scrollContainerRef}
            onScroll={handleScroll}
            style={listStyle}
          >
            {loadingSessions && <SessionsSkeleton />}

            {!loadingSessions && hasNoChats && <NoChats />}

            {!loadingSessions && !sessionsError && !hasNoChats && (
              <>
                {Object.entries(
                  categorizeSessions(flattenedSessions) ?? {}
                )?.map(
                  ([date, list]: [string, any], index: number) =>
                    list.length > 0 && (
                      <Box mb={4} key={index}>
                        {/* chat date */}
                        <ChatDate date={date} />
                        {/* available chats within correspondat date */}
                        {list.map((session: any) => {
                          // Remove asterisk from title text
                          const cleanedTitle = session.title?.replace(
                            /\*\*/g,
                            ""
                          );
                          return (
                            <ChatItem
                              id={session?.id}
                              key={session?.id}
                              title={cleanedTitle}
                              isDisabled={false}
                              isSelected={session?.id === id}
                              onExportChat={handleExportChat}
                              onDeleteSession={handleDeleteSession}
                              onUpdateChatTitle={(id: string) =>
                                handleRenameChatTitle(id, cleanedTitle)
                              }
                            />
                          );
                        })}
                      </Box>
                    )
                )}
                {isFetchingNextPage && <SessionsLoadingIndicator />}
              </>
            )}
          </CustomThickScrollBar>

          {/* background shadow effect at the bottom of sessions list */}
          {flattenedSessions.length > 0 && <BackgroundShadowEffect />}
        </Flex>
      </Flex>

      {/* Rename Chat Modal */}
      <RenameModal
        existingTitle={selectedChatTitle}
        updating={updating}
        isOpen={showRenameModal}
        onClose={() => {
          setShowRenameModal(false);
          setSelectedSession(null);
        }}
        onUpdateChatTitle={handleUpdateChatTitle}
      />

      {/* Export Chat Modal */}
      {showExportModal && (
        <ExportModal
          id={selectedSession}
          isOpen={showExportModal}
          onClose={() => {
            setShowExportModal(false);
            setSelectedSession(null);
          }}
        />
      )}

      {/* Delete Chat(s) Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedSession(null);
        }}
        onConfirm={handleConfirmDeleteSession}
        header={"Delete chat"}
      >
        {deleting ? (
          <Loading message={"Deleting chat.."} />
        ) : (
          <Text fontFamily={"Poppins, sans-serif"} fontSize={"14px"}>
            {"This chat will no longer be available"}
          </Text>
        )}
      </DeleteModal>
    </>
  );
}
