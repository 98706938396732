import {
  Card,
  CardBody,
  Circle,
  Flex,
  Heading,
  Icon,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { ToolProps } from "models/tools/ToolsProps";
import { HiOutlineWrench } from "react-icons/hi2";
import { hexToRgba } from "utils/helpers";

interface ToolCardProps {
  tool: ToolProps;
  onOpen: (name: string) => void;
}

function ToolCard({ tool, onOpen }: ToolCardProps) {
  // Theme
  const { colors } = useTheme();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgCard = isLight ? "background" : `lightBackground`;

  return (
    <Card
      bg={bgCard}
      _hover={{
        bg: hexToRgba(colors.secondary[100], 0.15),
        cursor: "pointer",
      }}
      // onClick={() => onOpen(tool?.name)} // TO BE UNMUTED WHEN INTERACTION WITH TOOLS PAGE IS HANDLED
    >
      <CardBody border="none" fontSize={[null, null, null, "xs", "xs", "sm"]}>
        <Flex gap={6} align={"center"} flex={1} h={"100%"}>
          <Circle
            size={"50px"}
            borderColor={hexToRgba(colors.secondary[200], 0.4)}
            color={"gray.600"}
            borderWidth={1}
            bg={hexToRgba(colors.secondary[100], 0.3)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={HiOutlineWrench} boxSize="20px" />
          </Circle>
          <Flex
            h={"100%"}
            direction={"column"}
            justify={"space-between"}
            gap={5}
            color={"gray.600"}
            flex={1}
          >
            <Flex direction={"column"} gap={1}>
              <Heading size="xs" textTransform={"capitalize"}>
                {tool?.name}
              </Heading>
              <Text fontSize={"xs"} w={"90%"}>
                {tool?.description}
              </Text>
            </Flex>

            {/* <Text
              fontSize={"xs"}
              color={"secondary.400"}
              maxW={"90%"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {tool?.end_point_url}
            </Text> */}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default ToolCard;
