import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { CoreContext } from "views/library/core";
import { SubtitleSM } from "components/ui/Subtitle";
import PropsCard from "components/molecules/PropsCard";
import PhaseChart from "components/molecules/PhaseChart";
import SmilesWithTag from "components/molecules/SmilesWithTag";
import { CompoundProps } from "models/compounds/CompoundProps";
import AssaysTable from "components/molecules/assays/AssaysTable";
import PharmaAccordion from "components/molecules/PharmaAccordion";
import ExternalSourcesList from "components/molecules/ExternalSourcesList";
import ChemPropsTable from "components/molecules/chemicalProperties/ChemPropsTable";

// Helpers
import HelperModal from "components/molecules/HelperModal";

import ReferencesChart from "components/molecules/references/chart/ReferencesChart";

import MainPanelError from "components/ui/MainPanelError";
import ActionButton from "components/buttons/ActionButton";
import { useCompoundsAPI } from "api/useCompoundsAPI";
import { errorHandler, isWithinLimit } from "utils/helpers";
import {
  activityDataHelper,
  capitalise,
  chemPropsHelper,
  externalSourcesHelper,
  moaPropsHelper,
  papersHelper,
  pharmaPropsHelper,
  screeningDataPropsHelper,
  similarSummaryPropsHelper,
} from "components/molecules/helpers";
import FoldersModal from "components/library/bookmarks/modal/FoldersModal";
import Ontologies from "components/molecules/Ontologies";
import MoleculesTable from "components/molecules/MoleculesTable";
import { IoIosFlash } from "react-icons/io";
import { BsBookmarkCheck } from "react-icons/bs";

export default function MoleculeView() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const { sourceItemId: id } = useParams();
  const { setSourceItemName } = useContext(CoreContext);

  const navigate = useNavigate();

  // States
  const [showSaveModal, setShowSaveModal] = useState(false);

  // APIs
  const { fetchCompoundsById, fetchPapersReferencedIn } = useCompoundsAPI();

  // API
  // * Fetch compound
  const {
    isLoading: compoundIsLoading,
    error: compoundError,
    data: compound,
  } = useQuery({
    queryKey: ["compound", id],
    queryFn: fetchCompoundsById,
    staleTime: Infinity, // always fresh since it rarely changes
    gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
    retry: 1,
  });

  // to display source name in breadcrumb
  useEffect(() => {
    if (compound) {
      setSourceItemName(compound?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compound]);

  // * Fetch similar compounds
  const similarCompoundsQueries = useQueries({
    queries: compound?.similar_compounds
      ? compound.similar_compounds.map((cmp: any) => {
          return {
            queryKey: ["compound", cmp.compound_ikey],
            queryFn: fetchCompoundsById,
            staleTime: Infinity, // always fresh since it rarely changes
            gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
            retry: false,
          };
        })
      : [],
  });

  const similarCompounds = useMemo(() => {
    return similarCompoundsQueries
      ? similarCompoundsQueries
          .filter((query) => query.data !== undefined)
          .map((query) => query.data as CompoundProps)
      : [];
  }, [similarCompoundsQueries]);

  // * Fetch papers referenced in
  const {
    isLoading: papersReferencedInIsLoading,
    error: referencedInError,
    data: papersReferencedIn,
  } = useQuery({
    queryKey: ["compound", "paper", compound?.name],
    queryFn: fetchPapersReferencedIn,
    staleTime: Infinity, // always fresh since it rarely changes
    gcTime: 30 * 1000 * 60, // unused cache is cleared after 30 mins
    enabled: !!compound,
    retry: 1,
  });

  // if error
  if (compoundError || referencedInError) {
    let err = compoundError ? compoundError : referencedInError;
    return (
      <Flex w={"100%"} h={"100%"} my={10}>
        <MainPanelError errorMessage={err && errorHandler(err).message} />
      </Flex>
    );
  }

  // if Loading
  if (compoundIsLoading)
    return (
      <Flex
        direction={"column"}
        w={"100%"}
        h={"100%"}
        align={"center"}
        justify={"center"}
        my={10}
      >
        <Loading message="Loading compound data.." />
      </Flex>
    );

  return (
    <Box>
      {/* if data fetched successfully */}
      {compound ? (
        <VStack spacing={4} align="stretch">
          {/* Name & Aliases */}
          <Flex direction={"column"} gap={2} w={"100%"}>
            {/* Header */}
            <Flex gap={5} align={"center"} justify={"space-between"} w={"100%"}>
              {/* compound name */}
              <Tooltip
                label={capitalise(compound?.name || "")}
                bg={"gray.900"}
                color={"gray.100"}
                hasArrow
                py={2}
                px={3}
                m={2}
                maxW={[null, null, null, "400px", "500px", "700px"]}
                borderRadius={"6px"}
                fontSize={"14px"}
                boxShadow={"none"}
                arrowSize={8}
              >
                <Heading
                  size={[null, null, null, "md", "lg", "lg"]}
                  color={"secondary.700"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  w={"100%"}
                  maxW={"700px"}
                  paddingTop={3}
                  paddingBottom={3}
                  lineHeight={1}
                >
                  {capitalise(compound?.name || "")}
                </Heading>
              </Tooltip>

              {/* Save button */}
              <ActionButton
                aria-label={"Save molecule"}
                label={"Save molecule"}
                placement={"left"}
                icon={<BsBookmarkCheck />}
                onClick={() => setShowSaveModal(true)}
              />
            </Flex>

            {/* Aliases */}
            {compound?.aliases && compound?.aliases?.length > 0 && (
              <HStack spacing={2} mb={4} align={"flex-start"}>
                <Text color={"gray.500"} fontSize={"14px"}>
                  <Text as={"span"} fontWeight={"500"}>
                    {compound?.aliases.length < 2 ? "Alias:" : "Aliases:"}
                  </Text>{" "}
                  {compound?.aliases?.join(" · ")}
                </Text>
              </HStack>
            )}
          </Flex>

          {/* Chemical Properties */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"}>
              <SubtitleSM>Chemical Properties</SubtitleSM>
              <HelperModal
                title={"Chemical Properties"}
                data={chemPropsHelper}
              />
            </Flex>

            <Flex direction="row" gap={5} mb={5} align={"center"}>
              {/* SMILES image */}
              <SmilesWithTag
                canonic_url={
                  compound?.chemical_props?.isomeric_smiles_structure
                }
                isometric_url={
                  compound?.chemical_props?.canonical_smiles_structure
                }
              />

              {/* General Info */}
              <ChemPropsTable
                chemProperties={compound?.chemical_props}
                origin={compound?.citeline_data?.origin_compound?.join(", ")}
              />
            </Flex>
          </PropsCard>

          {/* Pharmacological Properties */}
          <PropsCard>
            <Flex align={"baseline"}>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Pharmacological Properties</SubtitleSM>
                <HelperModal
                  title={"Phamacological Properties"}
                  data={pharmaPropsHelper}
                />
              </Flex>
              <Spacer />

              {/* Phase */}
              <PhaseChart
                selected={
                  compound?.citeline_data?.phase ??
                  compound?.integrity_data?.phase ??
                  compound?.gvk_data?.phase ??
                  ""
                }
              />
            </Flex>

            {/* Pharmacological Properties */}
            <PharmaAccordion compound={compound} />
          </PropsCard>

          {/* Similar compounds */}
          {!!similarCompounds.length && (
            <PropsCard>
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Similar Compounds</SubtitleSM>
                <HelperModal
                  title={"Compounds Summary Data"}
                  data={similarSummaryPropsHelper}
                />
              </Flex>

              <MoleculesTable
                items={similarCompounds}
                similarityScores={compound?.similar_compounds?.map(
                  (similar: any) => similar.similarity_score
                )}
              />

              {!isWithinLimit(user) && (
                <Flex justify="space-between" align="center" my={4}>
                  <Text
                    color={"gray.500"}
                    fontFamily={"Poppins, sans-serif"}
                    fontSize={"14px"}
                  >
                    Upgrade your plan to view other similar compounds.
                  </Text>
                  <Flex align="center" gap={1} alignItems="flex-end" mr={4}>
                    <Button
                      type={"submit"}
                      borderRadius={"30px"}
                      bg={"highlight.primary"}
                      color={"background"}
                      h={"fit-content"}
                      w={"fit-content"}
                      py={2}
                      px={3}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      letterSpacing={".02rem"}
                      leftIcon={<IoIosFlash />}
                      _active={{ bg: "highlight.primary" }}
                      _hover={{ bg: "highlight.primary" }}
                      _focus={{ bg: "highlight.primary" }}
                      _focusWithin={{ bg: "highlight.primary" }}
                      onClick={() => navigate("/pricing")}
                    >
                      Upgrade
                    </Button>
                  </Flex>
                </Flex>
              )}
            </PropsCard>
          )}

          {/* Assays */}
          {/* Assay Hits */}
          {compound?.assays_hits && compound?.assays_hits?.length > 0 && (
            <PropsCard>
              {/* Title */}
              <Flex align={"flex-end"} mb={4} gap={1}>
                <SubtitleSM>Activity Data</SubtitleSM>
                <HelperModal title="Activity Data" data={activityDataHelper} />
              </Flex>

              {/* Assay Hits */}
              <AssaysTable assays={compound?.assays_hits} isHit={true} />
            </PropsCard>
          )}

          {/* Screening collection */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Primary Screening Data</SubtitleSM>
              <HelperModal
                title="Primary Screening Data"
                data={screeningDataPropsHelper}
              />
            </Flex>

            {/* Assay Hits */}
            <AssaysTable assays={compound?.screening_collection} />
          </PropsCard>

          {/* Ontologies collection */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>Ontology</SubtitleSM>
              <HelperModal title="Ontology" data={moaPropsHelper} />
            </Flex>

            {/* External Resources List */}
            <Ontologies ontologies={compound?.moa} />
          </PropsCard>

          {/* External Resources */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>External Sources</SubtitleSM>
              <HelperModal
                title="External Sources"
                data={externalSourcesHelper}
              />
            </Flex>

            {/* External Resources List */}
            <ExternalSourcesList resources={compound?.external_resources} />
          </PropsCard>

          {/* References */}
          <PropsCard>
            {/* Title */}
            <Flex align={"flex-end"} mb={4} gap={1}>
              <SubtitleSM>References</SubtitleSM>
              <HelperModal title="References" data={papersHelper} />
            </Flex>

            {papersReferencedInIsLoading ? (
              <Loading message="Loading papers data.." />
            ) : papersReferencedIn.length > 0 ? (
              <ReferencesChart papersData={papersReferencedIn} />
            ) : (
              <Text>No research papers related to this compound.</Text>
            )}
          </PropsCard>
        </VStack>
      ) : (
        <Text textAlign={"center"}>No data for selected compound</Text>
      )}

      {/* Modal */}
      {id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "COMPOUND",
              content: {
                elementId: id,
                aliases: compound?.aliases,
                chemical_props: compound?.chemical_props,
                generic_name: compound?.generic_name,
              },
            },
            successMessage: `Molecule is successfully saved.`,
          }}
        />
      )}
    </Box>
  );
}
