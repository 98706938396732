import { useDispatch, useSelector } from "react-redux";
import { Box, Text, Divider } from "@chakra-ui/react";
import { selectCurrentNotificationsData } from "redux/features/notifications/notificationsSlice";
import NotificationsContainer from "./NotificationsContainer";
import NotificationsLoadingIndicator from "./NotificationsLoadingIndicator";
import { formatDate, getNotificationProps } from "./helpers";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { setError, setNotifications } from "redux/features/notifications/notificationsSlice";
import { useNotificationsAPI } from "api/notifications/useNotificationsAPI";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { NotificationsResponse } from "models/notifications/NotificationProps";

const NotificationsList = () => {
    const dispatch = useDispatch();
    const { getNotifications } = useNotificationsAPI();
    const { user } = useSelector(selectCurrentAuthData);
    const { notifications, error } = useSelector(selectCurrentNotificationsData);

    const {
        data,
        isLoading,
        error: fetchingError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<NotificationsResponse, Error, InfiniteData<NotificationsResponse>, [string], number>({
        queryKey: ["notifications"],
        queryFn: ({ pageParam }) => getNotifications({ pageParam }),
        getNextPageParam: (lastPage) =>
            lastPage.has_next ? lastPage.current_page + 1 : undefined,
        initialPageParam: 1,
        enabled: !!user,
        staleTime: 60000,
        retry: 1
    });

    useEffect(() => {
        if (data) {
            const allNotifications = data.pages.flatMap(page => page.notifications) || [];
            dispatch(setNotifications(allNotifications));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (fetchingError) {
            dispatch(setError(fetchingError.message));
        }
    }, [fetchingError, dispatch]);

    return (
        <Box px={1}>
            <Text fontSize="lg" fontWeight="bold">
                Notifications
            </Text>
            <Divider borderColor="gray.200" borderWidth="1px" my={3} />
            {isLoading ? (
                <NotificationsLoadingIndicator />
            ) : error ? (
                <Text
                    textAlign={"center"}
                    fontStyle={"italic"}
                    color={"red.400"}
                    fontSize={"sm"}
                >
                    Error loading notifications
                </Text>
            ) : notifications.length === 0 ? (
                <Text
                    textAlign={"center"}
                    fontStyle={"italic"}
                    color={"gray.500"}
                    fontSize={"sm"}
                >
                    no notifications
                </Text>
            ) : (
                <NotificationsContainer
                    notifications={notifications}
                    getNotificationProps={getNotificationProps}
                    formatDate={formatDate}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    onLoadMore={fetchNextPage}
                />
            )}
        </Box>
    );
};

export default NotificationsList;

