import { Modal, ModalOverlay, useBreakpointValue } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  onGuideClose,
  selectCurrentGuideData,
  updateCurrentModalPosition,
} from "redux/features/guide/guideSlice";
import { useDispatch, useSelector } from "react-redux";
import GuideModal from "./GuideModal";

function TourGuide() {
  const dispatch = useDispatch();
  const {
    isGuideOpen,
    currentSteps,
    currentStep,
    currentModalPosition,
    currentMode,
  } = useSelector(selectCurrentGuideData);

  const modalContent = currentSteps[currentStep - 1];
  const sidebarWidth = useBreakpointValue({
    lg: "260px",
    xl: "280px",
    "2xl": "300px",
  });

  const isElementInViewport = (el: any) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 50 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) - 120 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const scrollToElement = (el: any) => {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    const updatePosition = () => {
      if (!!currentSteps?.length && isGuideOpen) {
        const element = document.querySelector(
          `#${currentSteps[currentStep - 1]?.id}`
        );

        if (element) {
          if (
            !isElementInViewport(element) &&
            currentMode === "PAGE_INSTRUCTIONS"
          ) {
            scrollToElement(element);
            setTimeout(() => {
              updateModalPosition(element);
            }, 600); // Delay to ensure scroll is complete
          } else {
            updateModalPosition(element);
          }
        }
      }
    };

    const updateModalPosition = (element: any) => {
      const rect = element.getBoundingClientRect();
      const place = modalContent.guideTourPlacement || "RIGHT";
      const position = {
        top:
          place === "LEFT" || place === "RIGHT"
            ? rect.top + window.scrollY
            : place === "TOP"
            ? rect.top + window.scrollY
            : rect.top + window.scrollY + rect.height,
        left:
          place === "LEFT"
            ? rect.left
            : place === "TOP" || place === "BOTTOM"
            ? rect.left + rect.width / 2
            : rect.left + rect.width,
        elHeight: rect.height,
        elWidth: rect.width,
        placement: modalContent.guideTourPlacement || "RIGHT",
      };
      dispatch(updateCurrentModalPosition(position));
    };

    // Update position initially
    updatePosition();

    // Add resize event listener
    window.addEventListener("resize", updatePosition);

    return () => {
      // Remove resize event listener
      window.removeEventListener("resize", updatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSteps, isGuideOpen, currentStep, sidebarWidth]);

  if (!currentModalPosition) return null;
  return (
    <Modal
      isOpen={modalContent && isGuideOpen}
      onClose={() => dispatch(onGuideClose())}
      autoFocus={false}
    >
      <ModalOverlay backdropFilter="blur(1px)" zIndex={1000} />
      <GuideModal position={currentModalPosition} content={modalContent} />
    </Modal>
  );
}

export default TourGuide;
