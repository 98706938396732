import {
  Box,
  Flex,
  Icon,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { IoWarning } from "react-icons/io5";
import { hexToRgba } from "utils/helpers";


function Disclaimer() {
  // Theme
  const { colors } = useTheme();
  return (
    <Box
      fontSize={[null, null, null, "xs", "xs", "sm"]}
      borderColor={hexToRgba(colors.yellow[200], 0.4)}
      borderWidth={1}
      bg={hexToRgba(colors.yellow[200], 0.3)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={3}
      borderRadius={5}
    >
      <Flex gap={6} align={"center"} flex={1} h={"100%"}>
        <Icon
          as={IoWarning}
          boxSize="20px"
          color={colors.yellow[600]}
        />
        <Flex
          h={"100%"}
          direction={"column"}
          justify={"space-between"}
          color={"gray.600"}
          flex={1}
        >
          <Flex direction={"column"} gap={1}>
            <Text fontSize={"xs"} w={"90%"} color={colors.yellow[600]}>
              {"Tools for use through the assistant"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Disclaimer;
