import { Box } from "@chakra-ui/react";

interface ShadowOverlayProps {
    overlayPosition: "top" | "bottom";
    opacity: number;
    transition: string;
}

const ShadowOverlay: React.FC<ShadowOverlayProps> = ({
    overlayPosition,
    opacity,
    transition,
}) => {
    // Theme
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const isLight = colorMode === "light";
    const shadowColor = isLight
        ? "rgba(255,255,255,0.9), rgba(255,255,255,0)"
        : "rgba(50,50,50,0.9), rgba(50,50,50,0)";

    return (
        <Box
            position="absolute"
            left={0}
            right={0}
            height="20px"
            pointerEvents="none"
            bgGradient={`linear(to-${overlayPosition === "top" ? "b" : "t"
                }, ${shadowColor})`}
            {...(overlayPosition === "top" ? { top: 0 } : { bottom: 0 })}
            zIndex={2}
            opacity={opacity}
            transition={transition}
        />
    );
};

export default ShadowOverlay