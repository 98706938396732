import { StyleSheet } from "@react-pdf/renderer";
import { hexToRgba } from "utils/helpers";

export const styles = StyleSheet.create({
  body: {
    color: "#3f3f46",
    fontFamily: "Roboto",
    fontWeight: "normal",
    paddingHorizontal: 35,
    paddingBottom: 65,
    paddingTop: 35,
  },
  title: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "bold",
    paddingBottom: "8px",
    marginBottom: "20px",
    borderBottom: "1px solid #e4e4e7",
    fontSize: "18px",
  },
  question: {
    textAlign: "left",
    maxWidth: "80%",
    lineHeight: 2,
    fontSize: "14px",
    padding: "16px",
    marginLeft: "auto",
    marginBottom: "12px",
    borderRadius: "20px",
    backgroundColor: hexToRgba("#D5EEFF", 0.8),
  },
  heading: {
    fontSize: 18,
    marginBottom: 8,
  },
  paragraph: {
    lineHeight: 2,
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    marginVertical: "12px",
  },
  strong: {
    lineHeight: 2,
    fontSize: "14px",
    marginVertical: "12px",
    fontWeight: "bold",
  },
  em: {
    fontStyle: "italic",
    marginVertical: "12px",
    lineHeight: 2,
    fontSize: "14px",
    fontWeight: "normal",
    display: "flex",
    flexDirection: "row",
  },
  h1: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: 1.7,
    marginVertical: "10px",
    marginBottom: "18px",
  },
  h2: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: 1.7,
    marginVertical: "8px",
    marginBottom: "14px",
  },
  h3: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: 1.7,
    marginVertical: "6px",
    marginBottom: "10px",
  },
  h4: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: 1.7,
    marginVertical: "6px",
    marginBottom: "10px",
  },
  h5: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: 1.7,
    marginVertical: "6px",
    marginBottom: "10px",
  },
  h6: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: 1.7,
    marginVertical: "6px",
    marginBottom: "10px",
  },
  li: {
    lineHeight: 2,
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    marginVertical: "6px",
  },
  list: {
    lineHeight: 2,
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    marginVertical: "6px",
    gap: "8px",
    height: "100%",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function removeCitationsIDs(input: string): string {
  return input.replace(/\s*{{\[.*?\]}}/g, "");
}
