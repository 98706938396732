import { Flex, Icon, Text, useClipboard } from "@chakra-ui/react";
import { useState } from "react";
import { BiSolidCopy } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

interface UtrDetailProps {
  header: string;
  content: string | boolean | number;
  isOptimised?: boolean;
  isSequence?: boolean;
}

function UtrDetail({
  header,
  content,
  isOptimised,
  isSequence,
}: UtrDetailProps) {
  // State
  const [hasCopied, setHasCopied] = useState(false);

  // Hook
  const { onCopy } = useClipboard((content as string) || "");

  // Handler
  function handleCopy() {
    onCopy();
    setHasCopied(true);
  }

  return (
    <Flex align={"center"} gap={2} w={"fit-content"}>
      <Flex
        gap={1}
        align={"center"}
        fontSize={[null, null, null, "12px", "12px", "14px"]}
      >
        <Text fontWeight={"500"} lineHeight={"1.3"} color={"gray.500"}>
          {header}:
        </Text>
        <Text
          color={
            !isOptimised && typeof content !== "number"
              ? "gray.500"
              : typeof content === "number"
              ? "highlight.primary"
              : "orange.400"
          }
          fontWeight={"400"}
          lineHeight={"1.3"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          maxW={{ lg: "450px", xl: "600px" }}
        >
          {!isOptimised ? content : !!content ? "True" : "False"}
        </Text>
      </Flex>

      {isSequence && (
        <Icon
          display={"inline"}
          color={hasCopied ? "highlight.primary" : "gray.500"}
          boxSize={"14px"}
          onClick={handleCopy}
          cursor={"pointer"}
          as={hasCopied ? FaCheck : BiSolidCopy}
        />
      )}
    </Flex>
  );
}

export default UtrDetail;
